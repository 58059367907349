.preview {
  display: flex;
  padding: 5px 20px;
}
.preview p {
  width: 100px;
  margin: 0px;
  font-weight: 500;
}
.preview span {
  padding-left: 20px;
}

.headerBoard {
  background-color: #f4f4f4;
}
.headerBoard h3 {
  margin: 0px;
  color: #f46d25;
  font-weight: 700;
}

.split {
  border-right: 1px solid #f46d25;
}

.bookingcardnew {
  background: #ffffff;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin: 10px 0px;
}

.bookingcardnew .title {
  background: #f46d25;
  border-radius: 7px 7px 0px 0px;
  padding: 0px 10px;
  height: 30px;
  display: flex;
  align-items: center;
}
.bookingcardnew .title h2 {
  width: 100%;
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .split {
    border-right: none;
  }
  .bookingcardnew .preview p {
    width: 30%;
  }
  .bookingcardnew .preview span {
    width: 70%;
  }
}
