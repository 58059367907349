#file[type="file"] {
	display: none;
}
.label-holder {
	width: 100%;
	height: 50px;
  margin-top: 3em;
  display: grid;
  place-items: center;
  
}
.label{
  height: 50px;
  width: 200px;
  background-color:#2a3eb1;
  color: white;
  display: grid;
  place-items: center;
  font-size: 2.5rem ;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 80px;
}

.card img{
  width:100%;
  height: 180px;
  object-fit: cover;
  
}
.card1 {
  display: inline-block;
  width: 300px;
  height: 275px;
  margin: 10px;
}

.card {
  display: inline-block;
  width: 300px;
  margin: 10px;
}