.upcoming {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.upcoming td {
  text-align: left;
  padding: 8px;
}
.upcoming th {
  text-align: left;
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
}

.radio-group {
  border: solid 1px #f46d25;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
}

.customRadio {
  position: absolute;
  visibility: hidden;
  display: none;
}

.radio-group label {
  color: #000;
  display: inline-block;
  cursor: pointer;
  padding: 5px 20px;
}

input[type="radio"]:checked + label {
  color: #fff;
  background: #f46d25;
}

.radio-group label + input[type="radio"] + label {
  border-left: solid 1px #f46d25;
}

.accordion {
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin: 1.1em auto;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f46d25;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.accordion-title:hover {
  background-color: #f46d25;
}

.accordion-title {
  padding: 0.5rem;
}

.accordion-content {
  background-color: #fff;
}
.keyIcon {
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}

.gridSplit {
  width: calc(100% / 5);
  display: inline-block;
}

.dashboard-container {
  width: 97vw;
}

@media only screen and (max-width: 767px) {
  .dashboard-container {
    width: 100vw;
    margin-bottom: 20%;
  }

  .dashboard-container .revenue-info {
    width: 100%;
    margin-top: 13%;
    display: flex;
  }

  .revenue-info .revenue-items-container {
    overflow-x: scroll;
    margin-left: 7%;
  }

  .revenue-info .revenue-items-container::-webkit-scrollbar {
    height: 3px;
  }

  .revenue-info .duration-container {
    width: 100%;
  }

  .revenue-info .duration-container .duration {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 2.5%;
    align-items: baseline;
  }

  .dashboard-container .hotel-wise-revenue {
    width: 97.5%;
  }

  /* .dashboard-container
    .hotel-wise-revenue
    .accordion
    .accordion-title
    .hotel-options:hover {
    margin-left: -200px;
  } */

  .dashboard-container
    .hotel-wise-revenue
    .accordion
    .accordion-title
    .hotel-options
    .select-hotel {
    width: 190px;
    margin-left: -106px;
  }

  /* .dashboard-container
    .hotel-wise-revenue
    .accordion
    .accordion-title
    .hotel-options
    .select-hotel:hover {
    width: 190px;
    margin-left: -100px;
  } */
}
