.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem;
  border: none;
  outline: none;
}

.modalBody {
  flex: 1 1 auto;
  max-width: 25rem;
  backdrop-filter: blur(0.8rem);
  padding: 1rem;
  border-radius: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none !important;
  box-shadow: inset 0 -3em 3em #122f4b, 0.3em 0.3em 1em #000000;
  flex-wrap: wrap;
  /* height: 30rem; */
}

.header1 {
  font-size: clamp(1rem, 7vw, 1.5rem);
  margin: 0%;
  font-weight: bold;
  text-decoration: solid;
  background: linear-gradient(60deg, #f00 -25%, #fff300 168.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header2 {
  font-size: clamp(0.6rem, 5vw, 1rem);
  color: #f46d25;
}

@keyframes pop-in {
  100% {
    scale: 1;
  }
}

@media (max-width: 768px) {
  .modal {
    padding: 2rem 1rem;
  }
}
