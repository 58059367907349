.homeCard {
  background: #fff;
  border-radius: 0.8rem;
  padding: 0.3rem;
  aspect-ratio: 1/1.5;
  position: relative;
  object-fit: cover;
}

.homeCardImgItem {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
  height: 100%;
}

.overlay {
  position: absolute;
  content: "";
  inset: 0;
  margin: 0.3rem;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 786px) {
  .homeCard {
    width: 100%;
  }

  .limitedType {
    aspect-ratio: 1/0.7;
  }
}
