.imm {
  padding-bottom: 5px;
}

.imm .slick-slide img {
  display: block;
  margin: auto;
  height: 200px;
  width: 100%;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.imm .slick-prev {
  z-index: 100;
  left: -25px !important;
  top: 40%;
}

.imm .slick-next {
  z-index: 100;
  right: -10px !important;
  top: 40%;
}

.imm .slick-next:hover {
  display: block;
}

.imm .slick-next:focus {
  display: block;
}

.imm .slick-prev:before {
  color: black;
  font-size: 30px;
}
.imm .slick-next:before {
  color: black;
  font-size: 30px;
}

.card-inner {
  border-top-left-radius: "5px";
  height: 100%;
  /* box-shadow:1px 2px 8px rgb(1 1 1 /0.2); */
}
.card-item {
  cursor: pointer;
  transition: all 0.3s;
  height: 100%;
  /* margin-left: 5%; */
  margin: 10px 0 4px 5%;
  box-shadow: 1px 1px 7px rgb(1 1 1 /0.3);
  border: #000;
  width: 90% !important;
  border-radius: 5px;
  position: relative;
}

.card-item:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
}

.card-top img {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
}
.card-top span {
  position: absolute;
  color: #fff;
  background: #000;
  width: 100px;
  top: 15px;
  padding: 2px 0px 2px 2px;
  font-size: 13px;
  font-weight: 500;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.card-bottom {
  padding: 10px 10px;
  background-color: #fff;
}
.card-info {
  color: #000;
}
.card-info h5 {
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
}
.card-info h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  color: #f46d25;
}
.card-info p {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}
.card-info button {
  font-size: 14px;
  font-weight: 400;
  background-color: #f46d25;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px;
  margin-top: 5px;
}
