:root {
  --footer-border-radius: 0rem 0rem 0.2rem 0.2rem;
}

.destinationPageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.contentWithCarousel {
  padding: 2rem 8rem 4rem 8rem;
  background: rgba(13, 18, 28, 1);
  display: flex;
  box-sizing: border-box;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
/* .contentCard {
  flex: 1 1 200px;
} */

.slider {
  /* width: 50%; */
  height: 100%;
}
/* .sliderImage {
  width: 100% !important;
  height: 100% !important;
} */

.contentCard div {
  color: rgba(150, 150, 150, 1);
  font-size: 1rem;
}

.contentCard header {
  font-size: 2.3rem;
  color: rgba(232, 235, 247, 1);
  font-weight: bold;
  padding-bottom: 1rem;
}

.contentCard header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whereYouHeaded {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background: rgba(30, 35, 44, 0.7);
}

.whereYouHeaded header {
  color: #fff;
  font-size: 1.5rem;
  /* font-weight: bold; */
}

.whereYouHeaded header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whereYouHeaded footer {
  color: rgba(244, 109, 37, 1);
  font-size: 0.7rem;
}

.whereYouHeadedCardContainer {
  display: flex;
  gap: 1.2rem;
  width: 100%;
}

.whereYouHeadedCard {
  /* max-width: 8rem; */
  flex: 1 1 2rem;
  flex-wrap: wrap;
  transform: translateY(0);
  transition: all 0.3s;
  cursor: pointer;
}

.whereYouHeadedCard:hover {
  transform: translateY(-0.4rem);
}

.whereYouHeadedCard img {
  width: 100%;
  border-radius: 0.4rem;
}

.hillStationContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  padding: 3rem 5rem;
}

.hillStationContainer header {
  color: #fff;
  font-size: 1.6rem;
}

.hillStationContainer header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hillStationContainer div {
  text-align: center;
  color: rgba(150, 150, 150, 1);
  font-size: 0.9rem;
}

.hillStationCardList {
  display: flex;
  gap: 1.4rem;
  flex-wrap: wrap;
}

.hillStationCard {
  flex: 1 1 20%;
  transition: all 0.5s;
  cursor: pointer;
}

.hillStationCard:hover {
  transform: scale(1.1);
}

.hillStationCard img {
  width: 100%;
  aspect-ratio: 1/0.6;
  z-index: 10;
  /* height: 20rem; */
}

.hillStationCard footer {
  background: #fff;
  color: #000;
  padding: 0.3rem 0rem;
  border-radius: var(--footer-border-radius);
}

.thingsTodoContainer {
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.thingsTodoContainer header {
  color: #fff;
  font-size: 2rem;
}

.thingsTodoContainer header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thingsTodoCardContainer {
  display: flex;
  gap: 2rem;
}

.thingsTodoCard {
  width: 100%;
  flex: 1 1 20%;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
  border-radius: 0.3rem;
}

.thingsTodoCard:hover {
  transform: translateY(-0.2rem);
}

.thingsTodoCard img {
  width: 100%;
  aspect-ratio: 1/0.6;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
}

.thingsTodoCard footer {
  /* background-color: #fff; */
  color: rgba(244, 109, 37, 1);
  border-radius: var(--footer-border-radius);
  padding: 0.3rem 0rem;
}

.thingsTodoCard footer p {
  color: rgba(156, 156, 156, 1);
  font-size: 0.6rem;
}

.pilgrimsContainer {
  box-sizing: border-box;
  padding: 2rem 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.pilgrimsHeader {
  font-size: 2.5rem;
  color: #fff;
}

.pilgrimsHeader span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pilgrimsCardList {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.pilgrimsCard {
  flex: 1 1 40%;
  transition: all 1s;
  border-radius: 0.3rem !important;
  cursor: pointer;
  box-shadow: 0rem 0rem 1rem #000;
}

.pilgrimsCard img {
  width: 100%;
  aspect-ratio: 1/0.5;
  object-fit: fill;
  border-radius: 0.3rem 0.3rem 0rem 0rem;
}

.pilgrimsCard footer {
  background: #fff;
  color: #000;
  padding: 0.3rem 0rem;
  border-radius: var(--footer-border-radius);
  text-align: center;
  padding: 0.5rem 0rem;
}

.pilgrimsDescription {
  color: rgba(150, 150, 150, 1);
  padding: 0rem 3rem;
  text-align: center;
  font-size: 1.3rem;
}

.bestPlacesToVisit {
  display: flex;
  padding: 2rem 5rem;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
}

.bestPlacesToVisit header {
  color: #fff;
  font-size: 1.5rem;
  /* font-weight: bold; */
}

.bestPlacesToVisit header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bestPlacestoVisitCardContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.bestPlacestoVisitCard {
  flex: 1 1 10rem;
  cursor: pointer;
  transform: translateY(0);
  transition: all 1s;
}
.bestPlacestoVisitCard img {
  width: 100%;
}

.bestPlacestoVisitCard:hover {
  transform: translateY(-5px);
}

.bestPlacestoVisitCard footer {
  /* background-color: #fff; */
  color: rgba(244, 109, 37, 1);
  border-radius: var(--footer-border-radius);
  padding: 0.3rem 0rem;
}

.bestPlacestoVisitCard footer p {
  color: rgba(156, 156, 156, 1);
  font-size: 0.6rem;
}

.keyfactsContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
}

.keyfactsContainer header {
  color: #fff;
  font-size: 1.8rem;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 10;
}

.keyfactsContainer header span {
  background-clip: text;
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.keyfactsContainer img {
  width: 100%;
}

.keyFactsCardContainer::before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(13, 23, 28, 0.5), rgba(13, 23, 28, 0.5));
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.keyFactsCardContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 5rem;
  gap: 2rem;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.keyFactsCard {
  flex: 1 1 auto;
  width: 20%;
  background: #fff;
  padding: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  z-index: 10;
  font-size: 0.7rem;
  cursor: pointer;
  border-radius: 0.4rem;
}

.footerVariantTwo {
  color: rgba(244, 109, 37, 1);
  text-align: left;
}

.footerVariantTwo p {
  color: #fff;
  margin: 0;
  font-size: 0.5rem;
}

.cardFlexContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0rem;
  gap: 2rem;
}

.cardVariantOne {
  flex: 1 1 auto;
  width: 20%;
}

.cardVariantOne img {
  width: 100%;
}

.tourPackages {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 5rem;
  gap: 2rem;
}

.tourPackages header {
  background-clip: text;
  background: linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
}

.tourPackagesContent {
  background: #fff;
  width: 100%;
  height: 10rem;
}
