.most-popular-hotel-card {
  box-sizing: border-box;
  border-radius: 4px;
  flex: 1 1 200px;
  background-color: rgba(255, 255, 255, 1);
  width: 32%;
  height: fit-content;
  cursor: pointer;
}

.most-popular-hotel-card .image-badge {
  position: absolute;
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  /* color: white; */
  color: rgba(10, 29, 47, 1);
  font-weight: bold;
  font-size: 11px;
  padding: 0.5rem 1.7rem;
  border-radius: 4px;
  margin: 1.2rem 1.3rem;
}

.most-popular-hotel-card .card-image-container {
  /* height: 70%; */
  width: 100%;
  object-fit: cover;
  border: none;
  aspect-ratio: 1/0.75;
}

.most-popular-hotel-card .card-body {
  padding: 0.4rem 1.2rem;
}

.card-body .card-header small {
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  /* color: rgba(244, 109, 37, 1); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: clamp(0.6rem, 2vw, 0.8rem);
}

.card-body .card-header div {
  font-weight: bold;
  font-size: clamp(0.9rem, 4vw, 1.1rem);
  color: rgba(71, 71, 71, 1);
}

.card-footer small {
  border-top: 1px solid rgba(232, 235, 247, 1);
  font-size: clamp(0.4rem, 4vw, 0.85rem);
  color: rgba(232, 235, 247, 1);
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0.4rem 0rem;
}
.card-footer .rating-icon {
  color: rgba(254, 176, 25, 1);
  font-size: clamp(0.5rem, 3vw, 0.8rem);
}

.card-footer .no-of-reviews {
  font-size: clamp(0.5rem, 2vw, 0.7rem);
  color: rgba(178, 178, 178, 1);
  margin-left: 7px;
}

.card-footer .price-container .starts-from {
  /* color: rgba(244, 109, 37, 1); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
