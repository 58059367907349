@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.layout {
  width: 100%;
  box-sizing: border-box !important;
  font-family: "Poppins", sans-serif;
  height: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
}

.header {
  aspect-ratio: 16/6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(rgba(13, 23, 28, 1), rgba(13, 23, 28, 0));
}

.headerImage {
  width: 100%;
  height: 100%;
}

.headerTitle {
  color: #fff;
  position: absolute;
  font-size: clamp(2rem, 10vw, 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  z-index: 10;
}

.main {
  padding: 2rem;
}

@media (max-width: 768px) {
  .header {
    aspect-ratio: 1;
  }
  .main {
    padding: 2em 1em;
  }
}
