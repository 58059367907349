@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

#root-layout {
  position: relative;
  width: 100%;
  scroll-behavior: smooth;
}

#page-container {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  margin-top: -3.3rem;
  overflow: hidden; /* written this line to prevent overflow of the page container*/
}

.main-container {
  --x: 0;
  --y: 48.33%;
  margin: 0;
  padding: 0 !important;
  /* background: linear-gradient(80deg, #0a1d2f, #0d121c); */
  background: radial-gradient(
    88.33% 60.62% at var(--x) var(--y),
    rgba(18, 47, 75, 1) 0%,
    rgba(13, 18, 28, 1) 50%,
    rgba(13, 18, 28, 0) 100%
  );
  background-color: rgba(24, 30, 75, 1);
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  transition: 0.25s all;
  width: 100%;
  box-sizing: border-box;
}

.removeBackground {
  background: none;
  background-color: none;
}

#b2cScreen {
  box-sizing: border-box;
  font-family: "Manrope";
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: black;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: black;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f46d25;
    border-radius: 4px;
  }
}
