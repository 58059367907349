.sales-network-banner-container {
  position: relative;
  height: 600px;
  width: 100%;
}

.sales-network-banner {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sales-network-info {
  position: absolute;
  display: flex;
  gap: 8rem;
  justify-content: space-between;
  padding: 0rem 2rem;
  bottom: 0;
  align-items: center;
  /* border: 1px solid red; */
  height: 100%;
}

.phone-image-container {
  height: 500px;
}

.sales-section-title {
  color: white;
  font-size: clamp(1rem, 10vw, 3.8rem);
  font-weight: bolder;
  text-wrap: balance;
}

.sales-network-primary-text p {
  font-size: clamp(1rem, 3vw, 1.5rem);
  color: #fff;
}

.search-form {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  background: #ffffff;
  border-radius: 15px;
  align-items: center;
  padding: 0.3rem 0rem;
  background: transparent;
  gap: 0.7rem;
}

.search-form .submit-btn {
  border: none;
  outline: none;
  /* background-color: rgba(244, 109, 37, 1); */
  background: linear-gradient(0, #ffaa02, #ff4300);
  /* color: #ffffff; */
  color: rgba(10, 29, 47, 1);
  flex: 1 1 100%;
  padding: 0.6rem 1.5rem;
  max-height: 80%;
  border-radius: 5px;
  transition: 0.5s;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bolder;
}

.search-form .submit-btn:hover {
  /* filter: opacity(0.7);
  transition: 0.5s; */
  cursor: pointer;
  /* filter: hue-rotate(3.142rad); */
}

.search-form :is(.user-input-destination, .user-input-number) {
  border-radius: 5px;
  flex: 1 1 auto;
  width: 40%;
  border: none;
  background: #ffece1;
  backdrop-filter: blur(17.712465286254883px);
  outline: none;
  border-radius: 5px;
  height: 2rem;
  padding: 0.3rem 0.5rem;
}

.recieve-link-through {
  display: inline-flex;
  align-items: center;
  font-size: clamp(1rem, 6vw, 1.2rem);
  gap: 2rem;
  color: #ffffff;
}

.mobile-no-btn,
.email-btn {
  cursor: pointer;
}

.mobile-no-btn.send-link,
.email-btn.send-link {
  background-color: #f46d25;
  padding: 0.5rem 1.2rem;
  color: #ffffff;
  font-size: 1.2rem;
  border-radius: 1.05rem;
}

.form-header p {
  font-size: clamp(0.5rem, 3vw, 1rem);
}

@media (width< 786px) {
  .phone-image-container {
    display: none;
  }

  .sales-network-banner-container {
    width: 100%;
  }

  .search-form {
    width: 100%;
  }
  .user-input {
    width: 80%;
  }
  .sales-network-info {
    padding: 1rem;
    align-items: center;
  }
  .submit-btn {
    display: flex;
    align-items: center;
  }

  .b2c-footer-links {
    flex-direction: column;
    gap: 1rem;
  }

  .b2c-footer-links-container {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .b2c-footer-links-sub-container {
    align-items: start;
  }
  .sales-section-title {
    font-size: 1.6rem;
  }
}
