.captchaContainer {
  width: 100%;
}

.reloadButton {
  border: none;
  outline: none;
  background: none;
}

.wrapper {
  display: flex;
  align-items: center;
}
.userInput::placeholder {
  font-size: 0.8rem;
  padding: 0.5rem;
}
