.navbarContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  width: 100%;
  /* backdrop-filter: blur(1rem); */
  padding: 0.5rem 2rem;
  /* height: 3rem; */
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarContainer:is(:hover) {
  /* backdrop-filter: blur(1rem); */
  transition: all 1s;
}

.navlinkContainer {
  display: flex;
  list-style: none;
  padding: 0rem 0rem;
  margin: 0rem;
  color: #fff;
  font-size: 0.85rem;
  gap: 0.6rem;
  /* border: 1px solid red; */
  justify-content: center;
  min-height: 100%;
  font-weight: lighter;
}

.toggleBackground {
  background: #0e1a29;
}

.navHeader {
  cursor: pointer;
  min-height: 100%;
  /* border: 1px solid red; */
}

.navLinksDropDown {
  min-height: 100%;
}

.linkContainer {
  position: absolute;
  list-style: none;
  padding: 0.2rem;
  width: fit-content;
  box-sizing: border-box;
  background: #fff;
  color: #fff;
  padding: 0.2rem;
  top: 3.3rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  font-size: 0.9rem;

  /* backdrop-filter: blur(1rem); */
  /* display: none; */
}

.navHeader:is(:hover) > .linkContainer {
  background: red !important;
}

.linkContainer :is(li) {
  padding: 0.2rem;
}

.linkContainer :is(li:hover) {
  background: #f46d25;
  color: #fff;
  cursor: pointer;

  border-radius: 0.3rem;
  box-sizing: border-box;
}

.navbarItems {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  border: 1px solid red;
  align-items: center;
  background-color: green;
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .navbarContainer {
    background: #0e1a29;
  }
}

@media screen and (max-width: 600px) and (min-width: 300px) {
  .navlinkContainer {
    display: none;
  }

  .navbarContainer {
    padding: 0.5rem 1rem;
    background: #0e1a29;
  }
}
