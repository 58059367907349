@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");

.section {
  padding: 8em 2em 2em 2em;
  display: flex;
  gap: 2rem;
  position: relative;
  align-items: flex-start;
}

.mainBody {
  width: 85%;
  margin-top: -4.3rem;
}

.header {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.refundPolicyContainer {
  border-radius: 4px;
  border: 1px solid #9a9ea6;
  background: #ffefe7;
  padding: 1.5rem 1rem;
  color: #5e6282;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
}

.refundPolicyQuestion {
  font-weight: bolder;
}
.refundPolicy {
  margin: 0.5rem 0rem 0.2rem 1rem;
}

.orderList {
  list-style: lower-alpha;
  margin: 0.4rem 0rem 0rem 0rem;
}

.refundPolicyContainer:not(:first-child, :last-child) {
  margin-bottom: 1rem;
}

.cancellationPolicy {
  margin-top: 2rem;
}

.importantNotesContainer {
  margin-top: 3rem;
}

.important {
  background: linear-gradient(0deg, #f00 -25%, #fff300 168.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: bolder;
}

.importantPoints {
  border-radius: 4px;
  border: 1px solid #9a9ea6;
  backdrop-filter: blur(4rem);
  padding: 1.5rem 1rem;
  color: #5e6282;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
}

.orderList li:not(:last-child) {
  margin-bottom: 1rem;
}

.aside {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    padding: 8em 1em 1em 1em;
  }
  .mainBody {
    width: 100%;
    margin-top: -4.3rem;
  }
}
