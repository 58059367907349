$small: 300px;
$medium: 900px;

.container {
  margin: 0px 40px;
  display: flex;
  align-items: center;
  vertical-align: center;
  height: 100%;
  min-height: 100vh;

  .contentLogin {
    width: 30%;
    height: 100%;

    h1 {
      margin: 0px;
      font-weight: 600;
      font-size: 25px;
    }

    .error {
      color: red;
    }

    img {
      vertical-align: bottom;
      height: 55px;
    }

    p {
      margin: 0px;
      margin-bottom: 20px;
    }

    .linking {
      margin: 0;
      text-align: center;
      margin: 10px 0px;
    }

    .powered {
      img {
        height: 25px;
      }

      h3 {
        margin: 0px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .sideimage {
    width: 70%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: $small) {}

  @media screen and (max-width: $medium) {
    .contentLogin {
      width: 100%;

      h1 {
        margin: 0px;
        font-weight: 600;
        font-size: 24px;
      }

      img {
        height: 35px;
      }

      .powered {
        img {
          height: 23px;
        }

        h3 {
          font-size: 18px;
        }
      }
    }

    .sideimage {
      display: none;
    }
  }
}

.container {
  margin: 0px 40px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  min-height: 100vh;

  .contentRegister {
    width: 40%;
    height: 100%;

    h1 {
      margin: 0px;
      font-weight: 600;
      font-size: 32px;
    }

    .error {
      color: red;
    }

    img {
      vertical-align: bottom;
      height: 50px;
    }

    p {
      margin: 0px;
      margin-bottom: 20px;
    }

    .linking {
      margin: 0;
      text-align: center;
      margin: 10px 0px;
    }

    .powered {
      img {
        height: 25px;
      }

      h3 {
        margin: 0px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  .sideimage {
    width: 60%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: $small) {}

  @media screen and (max-width: $medium) {
    .contentRegister {
      width: 100%;

      h1 {
        margin: 0px;
        font-weight: 600;
        font-size: 24px;
      }

      img {
        height: 35px;
      }

      .powered {
        img {
          height: 23px;
        }

        h3 {
          font-size: 18px;
        }
      }
    }

    .sideimage {
      display: none;
    }
  }
}