.moret {

  a {
    line-height: 35px; //earlier here it is written as 3 changed to px
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    color: #212121;
    text-decoration: none;
    border-bottom: 1px solid #eee;
    display: inline-block;
    // padding-left: 20px;
    text-align: center;
  }

  .active-link {
    background-color: #F46D25;
    line-height: 3;
    width: 100%;
    color: #fff;
  }

}