.iconContainer {
  display: none;
  background: transparent;
  outline: none;
  border: none;
  padding: 0rem;
  margin: 0;
  position: relative;
}

.bar {
  width: 2rem;
  height: 3px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #d9d9d9;
  margin-bottom: 0.2rem;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .iconContainer {
    display: flex;
    /* transform: translateY(150%); */
    align-self: center;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: space-between;
    align-items: center;
  }

  [data-isOpen="true"] :nth-child(1) {
    transform-origin: right;
    rotate: -43deg;
    transition: all 250ms;
  }

  [data-isOpen="true"] :nth-child(2) {
    visibility: hidden;
    transition: all 0s;
  }
  [data-isOpen="true"] :nth-child(3) {
    transform-origin: right;
    rotate: 43deg;
    transition: all 250ms;
  }
}
