.destinationPage {
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  gap: 4rem;
}

.travelModes {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.travelCard {
  width: 100%;
  display: flex;
  gap: 2rem;
  background: #fff;
  border: 1px solid rgba(154, 158, 166, 1);
  border-radius: 4px;
  padding: 0.5rem;
}
.description {
  color: rgba(132, 127, 127, 1);
}

.description h3 {
  color: rgba(244, 109, 37, 1);
}
.travelCardList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 4rem;
}

@media (max-width: 768px) {
  .travelCard {
    flex-direction: column;
  }
}
