@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rozha+One&display=swap");

.topValuesSection {
  box-sizing: border-box;
}

.topValuesContent {
  display: grid;
  padding: 5rem 2rem;
  gap: 1rem;
  color: #fff;
  grid-template-areas:
    "topSectionInfo topSectionCardsContainer topSectionCardsContainer"
    "topSectionInfo topSectionCardsContainer topSectionCardsContainer";
}

.topSectionCardsContainer {
  display: flex;
  gap: 1.5rem;
  grid-area: topSectionCardsContainer;
}

.topSectionInfo {
  grid-area: topSectionInfo;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins";
}

.topSectionInfo > .subHeader {
  font-size: 1.25rem;
}

.topSectionInfo > .header {
  font-size: 2.25rem;
  padding: 0rem 0rem 0.75rem 0rem;
  margin: 0rem;
  font-weight: bold;
}

.topSectionCard {
  color: #fff;
  border: 1px solid rgba(33, 46, 58, 1);
  padding: 0.75rem;
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
  transform: scale(1);
  transition: transform 1s;
  box-sizing: border-box;
}

.topSectionCard:is(:hover) {
  transform: scale(1.1);
  transition: transform 1s;
}

.exploreDestinationsCard {
  background: #fff;
  border-radius: 0.8rem;
  object-fit: cover;
  aspect-ratio: 1/1.5;
  width: 30%;
  padding: 0.3rem;
  position: relative;
  color: #fff;
}
.cardOverlay {
  position: absolute;
  inset: 0;
  margin: 0.3rem;
  border-radius: 0.5rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  height: 100%;
  display: flex;
  flex-direction: column;
}

.intlPackageCardContent {
  position: absolute;
  inset: 0;
  align-self: flex-end;
  margin: 0.3rem;
  padding: 0.3rem;
  color: #fff;
}

.monthlyDeals {
  position: relative;
  max-width: 100%;
  box-sizing: border-box;
  aspect-ratio: 1/0.4;
}

.sectionBg {
  width: 100% !important;
  height: 100%;
}

.monthlyDealsContainer {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 0rem 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}

.dealInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #fff;
  flex: 1 1 30%;
  max-width: 30%;
}

.limitedTag {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 0.5rem;
  color: #f46d25;
  background: rgba(255, 239, 231, 1);
  padding: 0.2rem;
  width: fit-content;
  border-radius: 0.3rem;
}

.dealsTitle {
  font-size: clamp(1.2rem, 10vw, 3rem);
  font-weight: 600;
  color: #fff;
}
.dealSliderContainer {
  display: block;
  height: 70%;
  flex: 1 1 25%;
  max-width: 25%;
}

.dealLocationsContainer {
  color: #fff;
}

.dealLocationsContainer ul {
  list-style: none;
  padding-left: 0rem;
}

.dealLocationInfo {
  color: #000;
  background: #fff;
  border-radius: 1.1rem;
  padding: 1rem;
  cursor: pointer;
  transform: scale(1);
  transition: transform 500ms;
}

.dealLocationInfo:hover {
  transform: scale(1);
  transition: transform 500ms;
}

.activeDeal {
  background: linear-gradient(
    to right,
    rgba(255, 243, 0, 1),
    rgba(244, 109, 37, 1)
  );
  color: #fff;
  text-shadow: 0rem 0.1rem 0.3rem #000;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .intlPackageCardContent .locationName {
    font-size: 1rem !important;
  }
  .intlPackageCardContent .description {
    margin: 0 !important;
  }
  .intlPackageCardContent .cardPriceInfo .price {
    font-size: 1rem !important;
  }
  .intlPackageCardContent .cardPriceInfo .nightDay {
    font-size: 0.5rem !important;
  }

  /* domestic packages carousel  */
  .domestic-packages-carousel .intlPackageCardContent .locationName {
    font-size: 1rem !important;
  }
  .domestic-packages-carousel .intlPackageCardContent .description {
    margin: 0 !important;
  }
  .domestic-packages-carousel .intlPackageCardContent .price {
    font-size: 1rem !important;
  }
  .domestic-packages-carousel .intlPackageCardContent .nightDay {
    font-size: 0.5rem !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .intlPackageCardContent {
    margin: 0.15rem;
    padding: 0.15rem;
  }
  .intCardRating {
    font-size: 0.6rem;
  }
  .cardpriceinfo {
    background-color: red !important;
  }
}

@media screen and (max-width: 767px) {
  .topValuesContent {
    padding: 3rem 1rem 3rem 1rem;
    grid-template-areas:
      "topSectionInfo"
      "topSectionCardsContainer";
  }

  .topSectionCard {
    min-width: 70vw;
  }

  .topSectionCard:is(:hover) {
    transform: none;
  }

  .topSectionCardsContainer {
    overflow-y: scroll;
    scrollbar-width: none;
  }
}
