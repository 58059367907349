.section-most-popular-hotels {
  padding: 0 2rem;
  margin: 4rem 0px;
}

.section-most-popular-hotels header small,
.section-most-popular-hotels header p {
  padding: 0;
}

.section-most-popular-hotels header small {
  font-size: clamp(0.7rem, 3vw, 1.2rem);
  /* background: -webkit-linear-gradient(
    240deg,
    rgba(255, 0, 0, 1),
    rgba(255, 243, 0, 1)
  ); */
  background: -webkit-linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}

.section-most-popular-hotels .primary-text {
  font-size: clamp(1rem, 6vw, 3rem);
  color: rgba(232, 235, 247, 1);
  font-weight: 800;
}

.section-most-popular-hotels .primary-text .secondary-text {
  /* background: -webkit-linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: -webkit-linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-most-popular-hotels .title-container {
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
}

.section-most-popular-hotels .title-container a {
  /* color: rgba(232, 235, 247, 1); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(0.7rem, 3vw, 1.3rem);
  font-weight: 700;
  text-decoration: none;
}

.section-most-popular-hotels .title-container a:hover {
  color: rgba(232, 235, 247, 1);
  /* font-size: 20px; */
  font-weight: 700;
  text-decoration: underline;
}

.most-popular-hotels-container-home {
  margin-top: 2rem;
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .section-most-popular-hotels {
    padding: 0 1rem;
  }
}
