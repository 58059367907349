.trekInfoContainer {
  width: 100%;
  display: grid;
  grid-template-areas:
    "content content content"
    "navbar navbar navbar"
    "main main aside"
    "main main aside";

  row-gap: 1rem;
  column-gap: 1em;
  --headerFontSize: 2em;
  --sectionBorder: 1px solid rgba(33, 46, 58, 1);
  --sectionBackground: rgba(255, 255, 255, 0.03);
  box-sizing: border-box;
  line-height: 1.8;
}

.changeLayout {
  grid-template-areas:
    "content content content"
    "navbar navbar aside"
    "main main aside"
    "main main aside";
  transform: scale(1);
  transition: transform 2s;
}

.cardList {
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(33, 46, 58, 1);
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  align-items: center;
  grid-area: content;
}

.cardContainer {
  width: 100%;
  color: #fff;
  display: flex;
  gap: 0.3em;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.cardContainer div:is(:nth-child(2)) {
  font-weight: 550;
  text-align: center;
}

.cardContainer div:is(:nth-child(3)) {
  font-size: 0.75em;
  color: rgba(188, 188, 188, 1);
}

.nav {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(33, 46, 58, 1);
  color: #fff;
  grid-area: navbar;
  position: sticky;
  top: 5em;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
}

.toggleBackground {
  background: #101628;
  box-shadow: 0 0 2rem #000, 0 0 2rem #000;
  border-radius: 0.5rem;
  border: none;
  backdrop-filter: blur(10rem);
  z-index: 100;
}

.nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  position: sticky;
  top: 3.5rem;
}

.anchorTag {
  text-decoration: none !important;
}

.nav ul li {
  flex: 0 1 25%;
  padding: 0.5rem 1rem;
  text-wrap: nowrap;
  font-size: clamp(0.75rem, 8vw, 0.9rem);
  cursor: pointer;
  box-sizing: border-box;
}

.active {
  /* background: linear-gradient(
    to right,
    rgba(255, 243, 0, 1),
    rgba(244, 109, 37, 1)
  ); */
  background: #f46d25;
  font-weight: bold;
  /* position: absolute;*/
  transform: 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  /* left: var(--indicatorLeft);
  width: var(--indicatorWidth); */
}

.tabOption {
  display: none;
}

.tabLabel {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.sectionContainer {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(33, 46, 58, 1);
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 0.75em;
  scroll-margin-top: 12rem;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.aside {
  grid-area: aside;
  align-self: flex-start;
  position: sticky;
  top: 9em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.priceCard {
  width: 100%;
  align-self: flex-start !important;
}

.stayInfoSubHeader {
  color: #bcbcbc;
  width: 100%;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  /* gap: 2em; */
  justify-content: space-between;
  padding: 0.5em 0em;
}

.sectionHeader {
  font-size: var(--headerFontSize);
  font-weight: 600;
  padding: 0.8em 0em 0em 0em;
}

.coloredHeader {
  background: linear-gradient(#ff0000, #fff300);
  background-clip: text;
  color: transparent;
}

.aboutContent {
  display: grid;
  grid-template-areas:
    "abContent1 abVideo abVideo"
    "abContent2 abContent2 abContent2";
  column-gap: 1em;
}

.abContent1 {
  grid-area: abContent1;
  line-height: 1.8em;
}

.abVideo {
  grid-area: abVideo;
  border: 0.25em solid #fff;
  border-radius: 0.75em;
}

.abContent2 {
  grid-area: abContent2;
  padding-top: 0.5em;
}

.highlightsContent {
  width: 100%;
  height: 100%;
}

.expandContainer {
  width: 100%;
  height: 50%;
}

.bestTimeToVisitContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.contentAndImageContainer header {
  font-size: 1.5em;
  font-weight: 600;
}

.contentAndImageContainer {
  margin-bottom: 1em;
}

.timeToVisitContent {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.textContent {
  flex: 1 1 380px;
}

.mediaContainer {
  background-color: #fff;
  border-radius: 0.5em;
  /* height: fit-content; */
  padding: 0.5em;
  flex-grow: 1 1 370px;
  width: 700px;
  max-width: 400px;
  height: 100%;
}

.mediaContainer img {
  border-radius: 0.5em;
  width: 100%;
  object-fit: cover;
}

.imgContainer {
  width: 100%;
  background: #fff;
  border-radius: 1em;
  aspect-ratio: 1/0.5;
  padding: 0.5em;
  margin: 1em 0em;
}

.imgContainer img {
  border-radius: 1em;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.timeToVisitPoints {
  margin-top: 1em;
}

.fitness {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
}

.fitness div {
  flex: 1 1 370px;
}

.fitnessMedia {
  flex: 1 1 380px;
  width: 40%;
  background: #fff;
  border-radius: 0.5em;
  padding: 0.5em;
}

.fitnessMedia img {
  width: 100%;
  border-radius: 0.5em;
}

.trekItinerary {
  width: 100%;
  margin: 1em 0em;
  display: flex;
  gap: 1em;
}

.itineraryContentContainer {
  display: flex;
  flex-direction: column;
}

.trekItinerary header span {
  font-weight: 600;
  font-size: clamp(1em, 9vw, 1.5em);
}

.iconGradient {
  background: linear-gradient(#fff300, #f46d25);
  background-clip: text;
  -webkit-background-clip: text;
  padding: 0rem;
  margin: 0rem;
}

.itineraryHeader {
  font-size: clamp(1em, 10vw, 1.5em);
  font-weight: 600;
}

.policyContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.policyHeader {
  font-size: clamp(1rem, 7vw, 1.5rem);
  font-weight: 600;
}

.trekItineraryContainer {
  display: flex;
  align-self: flex-start;
  gap: 1.5rem;
  margin: 1rem 0rem;
}

.durationInfoContainer {
  display: flex;
  gap: 1rem;
  border: 1px solid #787878;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.6rem;
  padding: 0.5rem 1rem;
}

.hr {
  border: 1px solid #787878;
  min-height: 2rem;
  max-height: 2rem;
  align-self: center;
  /* transform: rotate(90deg); */
}
.itineraryInfoCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem;
}

.itineraryInfoContent {
  font-size: clamp(0.7rem, 5vw, 1rem);
  margin: -0.4rem;
}

.itineraryInfoContent {
  font-size: 0.8rem;
  color: #bcbcbc;
}

.trekItineraryImgContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.itineraryImgWrapper {
  flex: 1 1 150px;
  aspect-ratio: 1/0.5;
  border-radius: 0.5rem;
}

.itineraryImgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

@media (max-width: 768px) {
  .trekInfoContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "content content content"
      "navbar navbar navbar"
      "main main main"
      "aside aside aside";
  }

  .cardContainer {
    font-size: 0.8em;
  }

  .cardList {
    grid-template-columns: repeat(3, minmax(5rem, 1fr));
    transition: 2s;
  }

  .trekItinerary {
    flex-direction: column;
  }

  .aboutContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .durationInfoContainer {
    justify-content: space-between;
    flex-grow: 1;
  }

  .itineraryInfoCard {
    padding: 0rem;
  }
  .trekItineraryContainer {
    flex-direction: column;
    align-self: normal;
    width: 100%;
    flex-wrap: wrap;
  }

  .aboutContent,
  .highlightsContent {
    text-wrap: balance;
    text-align: justify;
  }

  .itineraryImgWrapper {
    flex: 1 1 400px;
    aspect-ratio: 1/0.5;
    border-radius: 0.5rem;
  }
}
