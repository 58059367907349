.bestTimePage {
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  gap: 4rem;
}

.main {
  box-sizing: border-box;
}

.aside {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 5rem;
  align-self: flex-start;
}

.topMostSection header {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

.topMostSection header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.topMostSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topMostSection .content {
  color: rgba(181, 181, 181, 1);
  line-height: 1.5rem;
}

.cardList {
  margin: 3rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.hr {
  width: 100%;
}

.bestMonthsToVisit {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 3rem;
}

.bestMonthsToVisit h2 {
  color: rgba(244, 109, 37, 1);
}

.bestMonthsCard {
  width: 22rem;
  border: 1px solid red;
  background: #fff;
  border: 1px solid rgba(154, 158, 166, 1);
  border-radius: 4px;
}

.cardImageContainer {
  width: 100%;
  aspect-ratio: 1/0.7;
  box-sizing: border-box;
  padding: 0;
}

.cardImageContainer img {
  width: 100%;
  height: 100%;
  /* display: none; */
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  color: rgba(132, 127, 127, 1);
}

.cardContent h3 {
  margin: 0;
  color: rgba(244, 109, 37, 1);
}

.bestMonthsCardList {
  display: flex;
  gap: 2rem;
}

@media (max-width: 768px) {
  .bestMonthsCardList {
    flex-direction: column;
  }
}
