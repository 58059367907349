.main {
  box-sizing: border-box;
}
.aside {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 9rem;
  align-self: flex-start;
}

.topMostSection header {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

.topMostSection header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.topMostSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.topMostSection .content {
  color: rgba(181, 181, 181, 1);
  line-height: 1.5rem;
}

.topMostSection .content ul {
  padding: 0;
  margin: 0;
}

.sectionContainer {
  box-sizing: border-box;
  width: 100% !important;
  padding: 4rem 2rem;
  display: flex;
  gap: 2rem;
}

.expandQueryForm {
  display: none;
  font-size: 1.6rem;
  position: fixed;
  top: 50%;
  margin: 0rem 0rem !important;
  right: -12%;
  z-index: 2;
  rotate: 90deg;
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column-reverse;
    padding: 4rem 1rem;
  }

  .aside {
    top: 4rem;
    display: none;
  }

  .aside :is(:nth-child(2), :nth-child(3)) {
    display: none;
  }

  .sectionContainer {
    padding: 0;
  }

  .main {
    padding: 2rem 1rem;
  }

  .expandQueryForm {
    --show: none;
    display: var(--show);
  }
}
