.placesToVisitPage {
  box-sizing: border-box;
  padding: 2rem;
  display: flex;
  gap: 4rem;
}

.main {
  box-sizing: border-box;
}
.aside {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 5rem;
  align-self: flex-start;
}

.topMostSection header {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

.topMostSection header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.topMostSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.topMostSection .content {
  color: rgba(181, 181, 181, 1);
  line-height: 1.5rem;
}

.placeToVisitList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.hr {
  width: 100%;
}

.placeToVisitList:last-child {
  margin-bottom: 4rem;
}
