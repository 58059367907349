.shoppingList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.3rem;
  margin: 3rem 0rem;
}

.placeHolderImageContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  gap: 1rem;
}

.cardContainer {
  flex: 1 1 15rem;
  transform: translateY(0);
  transition: transform 0.3s;
}

.cardContainer img {
  width: 100%;
  border-radius: 4px;
  filter: drop-shadow(0rem 0rem 1rem #000);
}

.cardContainer:hover {
  cursor: pointer;
  transform: translateY(-8px);
}

.placeHolderImageContainer p {
  margin: 0;
  color: rgba(181, 181, 181, 1);
}
