.imageList {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.imageCard {
  flex: 1 1 8rem;
  width: 10rem;
  aspect-ratio: 1/0.6;
  /* background: #fff; */
  border-radius: 4px;
  transform: translateY(0);
  transition: all 0.5s;
  cursor: pointer;
}

.imageCard:hover {
  transform: translateY(-0.6rem);
}

.imageCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.keyFactsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  margin: 3rem 0rem;
}
