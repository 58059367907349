.slider-container {
  width: 30%;
  border: 2px solid #fff;
  border-radius: 0.5em;
}

.slider-img-container {
  width: 100%;
}

.slider-img-container > img {
  width: 100%;
  aspect-ratio: 1/0.7;
  object-fit: cover;
  border-radius: 0.5em;
}

.slider ul li button::before {
  color: #fff;
  opacity: 1;
  font-size: 0.5rem;
}

.slider ul li.slick-active button::before {
  color: #f46d25;

  border-radius: 50%;
  opacity: 1;
}

@media (max-width: 768px) {
  .slider-container {
    width: 100%;
  }
}
