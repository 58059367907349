@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
.innerCirclelandingPage {
  height: 100%;
  // background-image: url("../../assets/noise.svg");
  // background-color: #0C0C0C;
  position: relative;
  color: rgba(255, 255, 255, 0.87);
  // color-scheme: light dark;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  padding: 2rem;
  margin: 0px;
  // mix-blend-mode: overlay;
}

.innerCirclelandingPage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(12, 12, 12, 1); /* Adjust opacity as needed */
}

.headerSectionMainBox {
  position: relative;
  z-index: 0;
}
.leftBlur {
  position: absolute;
  width: 35%;
  height: 200px;
  border-radius: 50%;
  // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
  // filter: blur(100px); /* Adjust blur amount as needed */
  pointer-events: none;
  /* Ensure the blur elements don't intercept clicks */
  opacity: 20%;
  left: -5%;
  top: 0%;
}

.headerSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 0px 0rem;
  .leftSide {
    box-sizing: border-box;
    .headerlogo {
      box-sizing: border-box;
      width: 100%;
      margin: auto;
    }
  }
  .rightSide {
    text-align: right;
    // width: 70%;
    box-sizing: border-box;
    margin-left: auto;
    .headingName {
      font-size: 60px;
      font-family: "Manrope";
      font-weight: 600;
      line-height: 20px;
    }
    .contentBox {
      width: 75%;
      margin-left: auto;
      p {
        font-size: 16px;
      }
      .box1 {
        display: flex;
        align-items: center;
        gap: 10px;
        h3 {
          line-height: 30px;
          // background: -webkit-linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background-clip: text;
          // text-fill-color: transparent;
          font-weight: 600;
        }
        .verticalLine {
          width: 10px;
          height: 100px;
          // background: linear-gradient(
          //   to bottom,
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background: linear-gradient(0,#ffaa02,#ff4300);
          margin: 12px 0px 0px 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .innerCirclelandingPage {
    width: fit-content;
  }
  .leftBlur {
    width: 40%;
    opacity: 40%;
  }
  .headerSection {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
    .rightSide {
      text-align: center;
      width: 100%;
      .headingName {
        font-size: 40px;
      }
      .contentBox {
        margin: auto;
        width: 100%;
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 600px) {
  .innerCirclelandingPage {
    width: fit-content;
  }
  .leftBlur {
    width: 40%;
    opacity: 40%;
  }
  .headerSection {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
    .rightSide {
      text-align: center;
      width: 100%;
      .headingName {
        font-size: 44px;
      }
      .contentBox {
        margin: auto;
        width: 100%;
        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .headerSection {
    padding: 0px;
    margin-top: 30px;
    .rightSide {
      width: 100%;
      .headingName {
        font-size: 40px;
        line-height: 0px;
      }
      .contentBox {
        margin: auto;
        width: 100%;
        p{
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
  .headerSection {
    padding: 0px;
    .rightSide {
      width: 100%;
      .headingName {
        font-size: 44px;
        line-height: 0px;
      }
      .contentBox {
        margin: auto;
        width: 100%;
      }
    }
  }
}

// --------------------------------------------------------Exciting Rewards styles---------------------------------------
.excitingRewardsmainBox {
  margin: 5rem 0px 0px 0px;
  position: relative;
  z-index: 1;
}
.rightBlur {
  position: absolute;
  width: 30%;
  height: 300px;
  border-radius: 50%;
  // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
  // filter: blur(100px); /* Adjust blur amount as needed */
  pointer-events: none;
  /* Ensure the blur elements don't intercept clicks */
  opacity: 20%;
  right: 0%;
  z-index: -1;
}
.excitingRewards {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  .leftline {
    position: absolute;
    width: 32%;
    height: 2px;
    background: linear-gradient(
      to left,
      rgba(255, 243, 0, 1),
      rgba(254, 102, 0, 1),
      rgba(0, 0, 0, 0)
    );
    border-radius: 5px;
    left: 0;
    z-index: 1;
    // border-image:
    // linear-gradient(
    //   to bottom,
    //   rgba(254, 102, 0, 1),
    //   rgba(255, 243, 0, 1)
    // ) 1 100%;
  }
  .rightline {
    position: absolute;
    width: 32%;
    height: 2px;
    background: linear-gradient(
      to right,
      rgba(255, 243, 0, 1),
      rgba(254, 102, 0, 1),
      rgba(0, 0, 0, 0)
    );
    border-radius: 5px;
    right: 0;
  }

  .headingName {
    background-color: #0f1825;
    border-radius: 50px;
    width: 40%;
    text-align: center;
    position: relative;
    h3 {
      font-weight: 700;
      // background: -webkit-linear-gradient(
      //   rgba(255, 243, 0, 1),
      //   rgba(254, 102, 0, 1)
      // );
      background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.excitingRewardsContent {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 5rem 0px 0px 0px;
  place-items: center;
  box-sizing: border-box;

  .leftSideContent {
    .mainBox {
      width: 60%;
      box-sizing: border-box;
      margin-left: auto;
      .box1 {
        height: 250px;
        // width: 350px;
        background-color: #0f1825;
        border-radius: 5px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box2 {
        h3 {
          // background: -webkit-linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-family: "Manrope";
          line-height: 27.2px;
          font-size: 21px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .rightSideContent {
    .mainBox {
      width: 60%;
      box-sizing: border-box;
      margin-right: auto;

      .box1 {
        height: 250px;
        // width: 350px;
        background-color: #0f1825;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box2 {
        h3 {
          // background: -webkit-linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-family: "Manrope";
          line-height: 27.2px;
          font-size: 21px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .rightBlur {
    width: 40%;
    opacity: 30%;
  }
  .excitingRewards {
    .leftline {
      width: 20%;
    }
    .rightline {
      width: 20%;
    }
    .headingName {
      width: 60%;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .excitingRewardsContent {
    // grid-template-columns: repeat(1, 1fr);
    text-align: center;
    flex-wrap: wrap;
    .leftSideContent {
      .mainBox {
        box-sizing: border-box;
        width: 100%;
        margin: auto;
        .box1 {
          margin: auto;
          box-sizing: border-box;
          height: 200px;
        }
        .box2 {
          p {
            font-size: 14px;
          }
        }
      }
    }
    .rightSideContent {
      .mainBox {
        box-sizing: border-box;
        width: 100%;
        margin: auto;
        .box1 {
          margin: auto;
          box-sizing: border-box;
          height: 200px;
        }
        .box2 {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .excitingRewardsContent {
    // grid-template-columns: repeat(1, 1fr);
    text-align: center;
    flex-wrap: wrap;
    .leftSideContent {
      .mainBox {
        box-sizing: border-box;
        width: 70%;

        margin: auto;
        .box1 {
          margin: auto;
          box-sizing: border-box;
        }
        .box2 {
          h3 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .rightSideContent {
      .mainBox {
        box-sizing: border-box;
        width: 70%;
        margin: auto;
        .box1 {
          margin: auto;
          box-sizing: border-box;
        }
        .box2 {
          h3 {
            font-size: 24px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .excitingRewards {
    .leftline {
      width: 31%;
    }
    .rightline {
      width: 31%;
    }
  }
  .excitingRewardsContent {
    .leftSideContent {
      .mainBox {
        width: 80%;
        .box1 {
          height: 180px;
        }
      }
    }
    .rightSideContent {
      .mainBox {
        width: 80%;
        .box1 {
          height: 180px;
        }
      }
    }
  }
}

// ----------------------------------------------joining Benefits------------------------------------------

.joinRewardsAndBenefitsbox {
  margin: 5rem 0px 0px 0px;
  position: relative;
  z-index: 0;
}
.rewardsleftBlur {
  position: absolute;
  width: 40%;
  height: 400px;
  border-radius: 50%;
  // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
  // filter: blur(100px); /* Adjust blur amount as needed */
  pointer-events: none;
  /* Ensure the blur elements don't intercept clicks */
  opacity: 20%;
  left: -15%;
  top: 50%;
}
.joinBenefits {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  .leftline {
    position: absolute;
    width: 32%;
    border-radius: 5px;
    height: 2px;
    background: linear-gradient(
      to left,
      rgba(255, 243, 0, 1),
      rgba(254, 102, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }
  .leftline {
    left: 0;
    z-index: 1;
  }
  .rightline {
    position: absolute;
    width: 32%;
    height: 2px;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      rgba(255, 243, 0, 1),
      rgba(254, 102, 0, 1),
      rgba(0, 0, 0, 0)
    );
  }

  .rightline {
    right: 0;
  }

  .headingName {
    background-color: rgba(15, 24, 37, 1);
    border-radius: 50px;
    width: 40%;
    text-align: center;
    position: relative;
    h3 {
      font-weight: 700;
      // background: -webkit-linear-gradient(
      //   rgba(255, 243, 0, 1),
      //   rgba(254, 102, 0, 1)
      // );
      background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.benefitsContentBox {
  display: flex;
  flex-wrap: wrap;
  gap: 200px;
  align-items: center;
  padding: 5rem 0px 0px 0px;
  // margin-top: 50px;

  .mobileBenifitsContainer {
    display: none;
  }

  // .benefitsContainer {
  //   // border: 4px solid transparent;
  //   // border-radius: 2rem;
  //   // border-left: none;
  //   // background: linear-gradient(to right, #0c0c0c, #0c0c0c),
  //   //   linear-gradient(
  //   //     to right,
  //   //     rgba(0, 0, 0, 0),
  //   //     rgba(255, 243, 0, 1),
  //   //     rgba(254, 102, 0, 1)
  //   //   );
  //   // background-color: transparent;

  //   // background-clip: padding-box, border-box;
  //   // background-origin: padding-box, border-box;
  //   border-width: 0.3rem;
  //   border-style: solid;
  //   border-image: linear-gradient(
  //       to right,
  //       rgba(0, 0, 0, 0),
  //       rgba(255, 243, 0),
  //       rgba(254, 102, 0)
  //     )
  //     1;
  //   border-left: none;
  //   border-bottom-right-radius: 100px;
  //   background-color: transparent;
  //   width: 350px;
  //   height: 300px;
  //   padding: 12px;
  //   position: relative;
  //   div {
  //     position: absolute;
  //     top: 70px;
  //     left: 250px;
  //     background: transparent;
  //     backdrop-filter: blur(1rem);
  //     margin: 0px;
  //     // box-shadow: 0rem 0rem 1rem rgba(255, 243, 1);
  //     padding: 1rem;
  //     border-radius: 1rem;

  //     h3 {
  //       // color: white;
  //       // line-height: 30px;
  //       background: -webkit-linear-gradient(
  //         rgba(255, 243, 0, 1),
  //         rgba(254, 102, 0, 1)
  //       );
  //       -webkit-background-clip: text;
  //       -webkit-text-fill-color: transparent;
  //       background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
  //       background-clip: text;
  //       text-fill-color: transparent;
  //       font-weight: 600;
  //       font-family: "Anton", sans-serif;
  //       font-size: 42px;
  //       width: 230px;
  //       margin: 0px;
  //     }
  //     p {
  //       font-family: "Anton", sans-serif;
  //       margin: 0px;
  //       font-size: 42px;
  //     }
  //   }
  // }

  .benefitsContainer {
    border: 4px solid transparent;
    border-radius: 0px 0px 100px 0px;
    border-left: none;
    // background: linear-gradient(to left,#0f1d2e,#0f1e2f),
    //   linear-gradient(
    //     to right,
    //     rgba(0, 0, 0, 0),
    //     rgba(255, 243, 0, 1),
    //     rgba(254, 102, 0, 1)
    //   );
      background: linear-gradient(to left,#0f1d2e,#0f1e2f),
      linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(255, 170, 2),
        rgba(255, 67, 0)
      );
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    width: 350px;
    height: 300px;
    padding: 12px;
    position: relative;
    div {
      position: absolute;
      top: 70px;
      left: 250px;
      // background: #0c0c0c;
      background: transparent;
      backdrop-filter: blur(1rem);
      margin: 0px;

      h3 {
        // color: white;
        // line-height: 30px;
        // background: -webkit-linear-gradient(
        //   rgba(255, 243, 0, 1),
        //   rgba(254, 102, 0, 1)
        // );
        background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        font-family: "Anton", sans-serif;
        font-size: 42px;
        width: 230px;
        margin: 0px;
      }
      p {
        font-family: "Anton", sans-serif;
        margin: 0px;
        font-size: 42px;
      }
    }
  }
  .listItems {
    position: relative;
    .verticalLine {
      width: 3px;
      height: 300px;
      // background: linear-gradient(
      //   to right,
      //   rgba(255, 243, 0, 1),
      //   rgba(254, 102, 0, 1)
      // );
      background: linear-gradient(to right, rgba(255, 67, 0), rgba(255, 170, 2));
      position: absolute;
      top: 28px;
      left: 26px;
    }
    ul {
      list-style-type: none;
      padding-left: 22px;
      position: relative;
      bottom: 2rem;
      li {
        position: relative;
        margin-top: 52px;
        padding-left: 22px;
        :before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
          border-radius: 50%;
          border: none;
        }
        span {
          color: white;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 624px) {
  .rewardsleftBlur {
    opacity: 30%;
    top: 30%;
  }
  .joinBenefits {
    .leftline {
      width: 20%;
    }
    .rightline {
      width: 20%;
    }
    .headingName {
      width: 60%;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .benefitsContentBox {
    gap: 30px;
    .mobileBenifitsContainer {
      display: block;
      margin: auto;
      box-sizing: border-box;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .benefitsContainer {
      display: none;
      border: 4px solid transparent;
      border-radius: 0px 0px 100px 0px;
      border-left: none;
      // background: linear-gradient(to right, #0c0c0c, #0c0c0c),
      //   linear-gradient(
      //     to right,
      //     rgba(0, 0, 0, 0),
      //     rgba(255, 243, 0, 1),
      //     rgba(254, 102, 0, 1)
      //   );
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      width: 280px;
      height: 220px;
      padding: 12px;
      position: relative;
      div {
        position: absolute;
        top: 70px;
        left: 250px;
        background: rgba(10, 15, 24, 1);
        margin: 0px;

        h3 {
          // color: white;
          // line-height: 30px;
          background: -webkit-linear-gradient(
            rgba(255, 243, 0, 1),
            rgba(254, 102, 0, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background: linear-gradient(
            rgba(255, 243, 0, 1),
            rgba(254, 102, 0, 1)
          );
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-family: "Anton", sans-serif;
          font-size: 24px;
          width: 120px;
          margin: 0px;
        }
        p {
          font-family: "Anton", sans-serif;
          margin: 0px;
          font-size: 21px;
        }
      }
    }
    .listItems {
      .verticalLine {
        height: 208px;
        // height: 165px;
        top: 0px;
        left: 26px;
      }
      ul {
        list-style-type: none;
        padding-left: 22px;
        li {
          margin-top: 22px;
          padding-left: 22px;
          :before {
            top: 50%;
            left: 0;

            width: 10px;
            height: 10px;
          }
          span {
            color: white;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 529px) and (max-width: 600px) {
  .benefitsContentBox {
    .listItems {
      .verticalLine {
        height: 200px;
        top: 0px;
      }
      ul {
        list-style-type: none;
        padding-left: 22px;
        li {
          margin-top: 22px;
          padding-left: 22px;
          :before {
            top: 50%;
            left: 0;
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 665px) {
  .benefitsContentBox {
    gap: 100px;
    .benefitsContainer {
      border: 4px solid transparent;
      border-radius: 0px 0px 100px 0px;
      border-left: none;
      // background: linear-gradient(to right, #0c0c0c, #0c0c0c),
      //   linear-gradient(
      //     to right,
      //     rgba(0, 0, 0, 0),
      //     rgba(255, 243, 0, 1),
      //     rgba(254, 102, 0, 1)
      //   );
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      width: 280px;
      height: 220px;
      padding: 12px;
      position: relative;
      div {
        position: absolute;
        top: 70px;
        left: 250px;
        background: rgba(10, 15, 24, 1);
        margin: 0px;

        h3 {
          // color: white;
          // line-height: 30px;
          // background: -webkit-linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-family: "Anton", sans-serif;
          font-size: 24px;
          width: 120px;
          margin: 0px;
        }
        p {
          font-family: "Anton", sans-serif;
          margin: 0px;
          font-size: 21px;
        }
      }
    }
    .listItems {
      .verticalLine {
        height: 215px;
        top: 0px;
      }
      ul {
        list-style-type: none;
        padding-left: 22px;
        li {
          margin-top: 22px;
          padding-left: 22px;
          :before {
            top: 50%;
            left: 0;
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 665px) and (max-width: 768px) {
  .benefitsContentBox {
    gap: 100px;
    .benefitsContainer {
      border: 4px solid transparent;
      border-radius: 0px 0px 100px 0px;
      border-left: none;
      // background: linear-gradient(to right, #0c0c0c, #0c0c0c),
      //   linear-gradient(
      //     to right,
      //     rgba(0, 0, 0, 0),
      //     rgba(255, 243, 0, 1),
      //     rgba(254, 102, 0, 1)
      //   );
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      width: 280px;
      height: 220px;
      padding: 12px;
      position: relative;
      div {
        position: absolute;
        top: 70px;
        left: 250px;
        background: rgba(10, 15, 24, 1);
        margin: 0px;

        h3 {
          // color: white;
          // line-height: 30px;
          // background: -webkit-linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // background: linear-gradient(
          //   rgba(255, 243, 0, 1),
          //   rgba(254, 102, 0, 1)
          // );
          background-clip: text;
          text-fill-color: transparent;
          font-weight: 600;
          font-family: "Anton", sans-serif;
          font-size: 24px;
          width: 120px;
          margin: 0px;
        }
        p {
          font-family: "Anton", sans-serif;
          margin: 0px;
          font-size: 21px;
        }
      }
    }
    .listItems {
      .verticalLine {
        height: 180px;
        top: 0px;
      }
      ul {
        list-style-type: none;
        padding-left: 22px;
        li {
          margin-top: 22px;
          padding-left: 22px;
          :before {
            top: 50%;
            left: 0;
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .joinBenefits {
    .leftline {
      width: 31%;
    }
    .rightline {
      width: 31%;
    }
  }
}

// -----------------------------------------Contact Box-----------------------------------

.contactMainBox {
  margin: 5rem 0px 0px 0px;
  display: flex;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  .box1 {
    // height: 350px;
    width: auto;
    // background: linear-gradient(
    //   25deg,
    //   rgba(255, 243, 0, 1),
    //   rgba(254, 102, 0, 1)
    // );
    background: linear-gradient(25deg, rgba(255, 67, 0), rgba(255, 170, 2));
    border-radius: 80px 0px 80px 80px;
    padding: 3%;
  }
}

@media screen and (max-width: 600px) {
  .contactMainBox {
    .box1 {
      height: auto;
      width: auto;
      padding: 8%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .contactMainBox {
    .box1 {
      height: auto;
      width: auto;
      padding: 5%;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .contactMainBox {
    .box1 {
      padding: 5%;
    }
  }
}
// ----------------------------------------Frequently asked-------------------------------------------

.frequentlyAskedmainBox {
  position: relative;
  margin: 5rem 0px 0px 0px;
  z-index: 0;
  .frequentlyCenterBlur {
    position: absolute;
    width: 80%;
    height: 30%;
    border-radius: 50%;
    background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
    filter: blur(100px);
    pointer-events: none;
    opacity: 20%;
    top: 50%;
    left: 10%;
  }
  .contentMainBox {
    padding: 5rem 0px 0px 0px;
    .headingSection {
      h3 {
        // background: -webkit-linear-gradient(
        //   rgba(255, 243, 0, 1),
        //   rgba(254, 102, 0, 1)
        // );
        background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        font-family: "Manrope";
        font-size: 34px;
        margin: 0px;
        display: flex;
        justify-content: center;
      }
      p {
        font-size: 22px;
        width: 60%;
        font-weight: 400;
        display: flex;
        margin: auto;
        text-align: center;
      }
    }

    // -----------------------accordion------------
    .accordionMainBox {
      padding: 50px 0px 0px 0px;
      .accordion {
        background-color: transparent;
        border-bottom: 1px solid rgb(164, 163, 163);
        width: 60%;
        margin: auto;
        .expandIcon {
          color: white;
        }
        .accordionSummary {
          .accordionTitle {
            color: white;
            font-size: 21px;
            font-weight: 400;
            margin: 0px;
          }
        }
        .accordionSummaryExpanded {
          .accordionTitle {
            // background: -webkit-linear-gradient(
            //   rgba(255, 243, 0, 1),
            //   rgba(254, 102, 0, 1)
            // );
            background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            // background: linear-gradient(
            //   rgba(255, 243, 0, 1),
            //   rgba(254, 102, 0, 1)
            // );
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 600;
            font-size: 21px;
            margin: 0;
          }

          .expandIcon {
            color: linear-gradient(rgba(255, 243, 0, 1), rgba(254, 102, 0, 1));
          }
        }
        .accordionDetails {
          margin: 0;
          .accordionContent {
            color: white;
            text-align: justify;
            margin: 0;
            margin-bottom: 5px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .frequentlyAskedmainBox {
    .frequentlyCenterBlur {
      left: 0%;
      height: 200px;
    }
    .contentMainBox {
      .headingSection {
        h3 {
          font-size: 24px;
          font-weight: 400;
          text-align: center;
        }
        p {
          font-size: 14px;
          width: auto;
          margin-top: 10px;
        }
      }
      // -----------------------accordion------------
      .accordionMainBox {
        .accordion {
          width: auto;
          .accordionSummary {
            .accordionTitle {
              font-size: 16px;
            }
          }
          .accordionSummaryExpanded {
            .accordionTitle {
              font-size: 16px;
              margin: 0;
            }
          }
          .accordionDetails {
            .accordionContent {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .frequentlyAskedmainBox {
    .frequentlyCenterBlur {
      left: 10%;
      height: 200px;
    }
    .contentMainBox {
      .headingSection {
        h3 {
          font-size: 28px;
          font-weight: 400;
        }
        p {
          font-size: 18px;
          width: auto;
          margin-top: 10px;
        }
      }
      // -----------------------accordion------------
      .accordionMainBox {
        .accordion {
          width: auto;
          .accordionSummary {
            .accordionTitle {
              font-size: 16px;
            }
          }
          .accordionSummaryExpanded {
            .accordionTitle {
              font-size: 16px;
            }
          }
          .accordionDetails {
            .accordionContent {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .frequentlyAskedmainBox {
    .contentMainBox {
      .headingSection {
        p {
          width: 80%;
        }
      }
      .accordionMainBox {
        .accordion {
          width: 80%;
        }
      }
    }
  }
}
