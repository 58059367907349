.contactCard {
  border-radius: 2px;
  background: linear-gradient(96deg, #fff300 -135.02%, #ff4300 152.24%);
  padding: 1rem 2rem;
  flex: 1 1 10rem;
}

.contactCardHeader {
  color: #fff;
  text-shadow: 2px 2px 3.3px rgba(0, 0, 0, 0.3);
  font-family: Poppins;
  font-size: 1.75em;
  font-style: normal;
  font-weight: 700;
}

.contactInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1 1 150px;
  width: 100%;
}

.info {
  color: #383838;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
}
