@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&family=Poppins:wght@200&display=swap");

.hotel-page-container {
  box-sizing: border-box;
  position: relative;
}

.illustration-container {
  position: relative;
}

.hotels-page-banner {
  width: 100%;
}

.hotel-banner-info-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #0d171c 0%, rgba(13, 23, 28, 0) 170.79%);
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  padding: 0rem 2rem;
  box-sizing: border-box;
}
.hotel-banner-header {
  color: #fff;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hotel-search-fields {
  border-radius: 8px;
  /* background: #fff; */
  backdrop-filter: blur(7rem);
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 1);
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 0.5rem 0.8rem;
}

.search-field {
  background: #ffff;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #e8ebf7;
  flex: 1 1 130px;
  /* box-shadow: 0rem 0rem 0rem none; */
  animation: glow 1s ease reverse;
  transition: all 1s;
}

.search-field img {
  border: none;
  padding: 0.3rem 0.7rem;
}

.search-field input {
  border: none;
  background: #e8ebf7;
  width: 80%;
}
.search-field input:focus {
  border: none;
  background: #e8ebf7;
  outline: none;
  border-color: transparent;
}

.btn-search-hotels {
  border-radius: 2px;
  /* background: linear-gradient(93deg, #fff300 -65.71%, #ff4300 116.55%); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  color: #ffffff;
  border: none;
  padding: 0.3rem 2rem;
  cursor: pointer;
  flex: 1 1 70px;
}

.search-field:focus-within {
  box-shadow: 0rem 0rem 1rem #f46d25;
  transition: box-shadow 0.5s;
}

.search-field:has(input::placeholder) {
  color: #273e47;
}

.search-field input::placeholder {
  color: #273e47;
  font-size: 0.8rem;
}

.hotels-body-container {
  padding: 1rem 2rem;
}

.hotel-description-now {
  border-radius: 4px;
  border: 1px solid #9a9ea6;
  background: #ffefe7;
  margin: 1rem 0rem;
  color: #5e6282;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  text-align: justify;
}

.hotel-description-now span {
  color: #ff6b00;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  cursor: pointer;
}

.hotel-description-now span:hover {
  border-bottom: 1px solid #ff6b00;
}

.most-popular-destinations,
.places-to-stay-container {
  box-sizing: border-box;
  padding: 1rem 2rem 3rem 2rem;
}

.most-popular-destination-header,
.places-to-stay-header {
  color: #fff;
  font-weight: bold;
  font-size: clamp(1rem, 6vw, 3rem);
}

.most-pop-img-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 1.5rem;
  /* width: 100%; */
}

.places-to-stay-items {
  display: flex;
  gap: 2rem;
  padding: 2rem 0rem;
  flex-wrap: wrap;
  width: 100%;
}

@media (max-width: 768px) {
  .hotels-page-banner {
    height: 100vh;
  }
  .hotels-body-container {
    padding: 1rem 1rem;
  }
  .most-popular-destinations {
    padding: 1rem 1rem 3rem 1rem;
  }
  /* .places-to-stay-container {
    padding: 1rem;
  } */
}
