.section {
  padding: 0rem 2rem;
  display: flex;
  gap: 1rem;
  position: relative;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  margin-top: -4rem;
}

.header {
  /* background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: bolder;
}

.paragraph {
  color: rgba(94, 98, 130, 1);
}
.question {
  color: #f46d25;
  font-weight: bold;
}

.answer {
  color: rgba(94, 98, 130, 1);
  text-wrap: balance;
}

.bulletPoints {
  font-weight: bold;
  color: #f46d25;
}

.bulletPoints :is(p) {
  font-weight: lighter;
  text-wrap: balance;
  color: rgba(94, 98, 130, 1);
}

.container {
  border: 1px solid rgba(154, 158, 166, 1);
  padding: 0.8rem;
  border-radius: 0.3rem;
  background: #fff;
  box-shadow: 0rem 0rem 2rem #f46d25;
}

.article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.aside {
  position: sticky;
  position: -webkit-sticky;
  /* margin-top: 20rem; */
  top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 300px;
  align-self: flex-start;
}
.main {
  flex: 4 1 300px;
}
.topSection {
  padding: 5rem 2rem;
  margin-top: 2rem;
}

.townoFamily {
  width: 100%;
  display: block;
  aspect-ratio: 1/1;
}

.footer :is(p, h3) {
  padding: 0;
  margin: 0;
}

.footer span {
  color: #f46d25;
  font-weight: bold;
}

.footer p {
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .topSection {
    padding: 5rem 1rem;
  }
  .section {
    padding: 0rem 1rem;
  }
}
