.popular-packages-card {
  flex: 1 1 300px;
  height: fit-content;
  overflow: hidden;
  box-sizing: border-box;
  transition: 1s;
  position: relative;
  cursor: pointer;
}

.popular-packages-card img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.popular-packages-card:hover {
  flex-grow: 4;
  transition: 1s;
}

.popular-packages-card .image-badge-package {
  background: orange;
  width: fit-content;
  /* color: #ffffff; */
  color: rgba(10, 29, 47, 1);
  margin-bottom: 2rem;
  /* background: linear-gradient(60deg, rgba(255, 243, 0, 1), rgba(255, 67, 0, 1)); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
}

.popular-packages-card .packages-card-content {
  position: absolute;

  /* display: flex;
  flex-direction: column; */

  top: 13%;
  width: 80%;
}

.content-container-pk {
  position: relative;
  width: 20rem;
  left: 3rem;
  color: #fff;
  font-size: clamp(0.8rem, 5vw, 1.3rem);
  text-wrap: balance;
}

.price-container-pk {
  color: #fff;
  font-size: clamp(1.2rem, 5vw, 1.8rem);
  font-weight: bolder;
  margin-left: 3rem;
  margin-top: 2rem;
}

.price-container-pk span {
  font-size: clamp(0.5rem, 2vw, 0.85rem);
}

.pk-book-now-btn {
  padding: 0.8rem 1.5rem;
  margin-left: 3rem;
  margin-top: 1rem;
  /* color: #fff; */
  color: rgba(10, 29, 47, 1);
  outline: none;
  border: none;
  border-radius: 2px;
  /* background: linear-gradient(93deg, #fff300 -65.71%, #ff4300 116.55%); */
  background: linear-gradient(0,#ffaa02,#ff4300);
  cursor: pointer;
}

@media (max-width: 768px) {
  .content-container-pk {
    width: 18rem;
  }
}
