@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");

.section {
  padding: 5em 2em 2em 2em;
}

.header {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.content-container {
  color: #5e6282;
  font-family: Plus Jakarta Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  text-align: justify;
  border-radius: 4px;
  border: 1px solid #9a9ea6;
  background: #fff;
}

.li::marker {
  color: #f46d25;
}

.li {
  padding: 0.6rem;
}

.subContent {
  padding: 0.5rem;
}

.content-container-with-header {
  margin-top: 2rem;
}
.content-header {
  background: linear-gradient(0deg, #f00 -27.59%, #fff300 124.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.8rem;
  font-weight: bolder;
}

.body {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  position: relative;
}

.main {
  width: 85%;
}

.aside {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .body {
    flex-direction: column;
  }
  .main {
    width: 100%;
  }
  .section {
    padding: 5rem 1rem 1rem 1rem;
  }
}
