.formGroup {
  border-radius: 4px;
  border: 1px solid #9a9ea6;
  background: #fff;
  /* margin-top: 1rem; */
  /* flex: 1 1 17rem; */
  /* height: 1rem; */
}

.formHeader {
  color: #f46d25;
  font-family: Poppins;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: bold;
  padding: 1rem 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 1rem;
  gap: 1rem;
}

.inputFields {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #ffefe7;
  padding: 0.3rem;
}

.inputFields:focus {
  border: 2px solid #9a9ea6;
  outline: none;
}

.inputFields::placeholder {
  color: #273e47;
  font-family: Poppins;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: bolder;
  letter-spacing: 0.26px;
}

.submitBtn {
  border-radius: 2px;
  background: linear-gradient(93deg, #fff300 -65.71%, #ff4300 116.55%);
  color: #ffffff;
  border: none;
  padding: 0.7rem 2rem;
  cursor: pointer;
  width: fit-content;
  text-align: center;
  text-shadow: 1px 1px 3.1px rgba(0, 0, 0, 0.51);
}

.submitBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.statusModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 0 1em 0.3rem #f46d25 !important;
  border-radius: 0.7rem !important;
}

.cardContent {
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
