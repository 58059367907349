.contactCard {
  width: 100%;
  /* border: 2px solid red; */
  padding: 1rem 0rem;
}

.header {
  font-weight: bold;
  font-size: clamp(1rem, 7vw, 2rem);
  color: white;
}

.addressContainer {
  display: flex;
  margin: 1rem 0rem;
  padding: 1rem 0rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.geoLocation {
  width: 50%;
  flex: 1 1 300px;
  box-shadow: 0rem 0rem 1rem #f46d25;
}

.address {
  width: 50%;
  color: white;
  text-wrap: balance;
  padding: 1rem;
  border: none;
  background: linear-gradient(60deg, #fff300 -65.71%, #ff4300 116.55%);
  flex: 1 1 300px;
}

.content {
  color: white;
  /* border: 1px solid red; */
  text-wrap: balance;
  padding: 1rem 0rem;
  font-size: clamp(0.6rem, 5vw, 1rem);
}

/* @media (max-width: 768px) {
  .address {
    flex-grow: 1;
  }
} */
