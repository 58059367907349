.avatar {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin: 15px auto;
  border: 1px solid #f46d25;
  border-radius: 50%;
  cursor: pointer;
}

.avatar img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.avatar span {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background: #fff8;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  transition: 0.3s ease-in-out;
}

.avatar:hover span {
  bottom: -2%;
}

#file_up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.view {
  padding: 30px;
  display: inline-block;
}

.view1 {
  padding: 30px;
  display: inline-block;
}

.avatarhotel {
  width: 250px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin: 15px auto;
  border: 1px solid #f46d25;
  cursor: pointer;
}

.avatarhotel img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.avatarhotel span {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background: #fff8;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  transition: 0.3s ease-in-out;
}

.avatarhotel:hover span {
  bottom: -2%;
}
