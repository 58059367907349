.contentList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}

.travelModes {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  margin: 3rem 0rem;
}

.travelCard {
  width: 100%;
  display: flex;
  gap: 2rem;
  background: #fff;
  border: 1px solid rgba(154, 158, 166, 1);
  border-radius: 4px;
  padding: 0.5rem;
}
.description {
  color: rgba(132, 127, 127, 1);
}

.description h3 {
  color: rgba(244, 109, 37, 1);
}
.travelCardList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.placeToVisitList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.hr {
  width: 100%;
}

@media (max-width: 768px) {
  .travelCard {
    display: flex;
    flex-direction: column;
  }

  .description h3 {
    margin: 0rem;
  }

  .instructions {
    text-align: justify;
  }
}
