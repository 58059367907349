.section-most-popular-packages {
  padding: 0 2rem;
  margin: 4rem 0px;
}

.primary-text .secondary-text {
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-most-popular-packages .title-container {
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
}

.section-most-popular-packages .title-container a {
  /* color: rgba(232, 235, 247, 1); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(0.7rem, 3vw, 1.3rem);
  font-weight: 700;
  text-decoration: none;
}

.section-most-popular-packages .title-container a:hover {
  color: rgba(232, 235, 247, 1);
  /* font-size: 20px; */
  font-weight: 700;
  text-decoration: underline;
}

.section-most-popular-packages .primary-text {
  font-size: clamp(1rem, 6vw, 3rem);
  color: rgba(232, 235, 247, 1);
  font-weight: 800;
  padding: 1.3rem 0px;
}

.most-popular-packages-container-home {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s, opacity 1s;
}

.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.show-header {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}

@media (max-width: 768px) {
  .section-most-popular-packages {
    padding: 0 1rem;
  }
}
