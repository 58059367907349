@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.destinationLayout {
  width: 100%;
  padding: 0;
  top: 2rem;
  box-sizing: border-box !important;
  /* position: relative; */
  font-family: Poppins;
}

.bannerContainer {
  width: 100%;

  box-sizing: border-box;
  object-fit: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85dvh;
}

.banner {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.backgroundVideo {
  width: 100% !important;
  box-sizing: border-box !important;
}
.bannerContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
  background: linear-gradient(rgba(13, 23, 28, 1), rgba(13, 23, 28, 0));
}

.primaryTitle {
  position: absolute;
  z-index: 12;
  color: rgba(255, 255, 255, 1);
  text-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.6);
  font-size: 3.125rem !important;
  font-weight: 600;
  text-align: center;
  transform: rotate(360deg);
  transition: all 2s;
  font-size: clamp(1.3rem, 7vw, 3.3rem);
  /* text-wrap: balance;*/
  width: 75%;
  /* padding: 0rem 4rem !important; */
}

.secondaryTitle {
  margin: 0.2rem;
  font-size: 2rem;
  font-size: clamp(0.4rem, 5vw, 2rem);
  font-weight: lighter;
}

@media (max-width: 768px) {
  .banner {
    aspect-ratio: 1;
  }
}
