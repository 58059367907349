$ScreenWidth: 768px;

@media screen and (max-width: $ScreenWidth) {
  .card-item {
    .card-inner {
      .card-bottom {
        .card-info {
          align-items: baseline;

          .property-name {
            width: 50%;
            padding-bottom: 5%;
          }
          .roombutton {
            height: 100%;
          }
        }
      }
    }
  }
}
