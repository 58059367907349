.weeks {
  display: flex;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 35px;
}

.react-datepicker__input-container input {
  width: 92%;
  border: 1px solid #f46d25;
  border-radius: 3px;
  height: 35px;
  padding: 0px 10px;
}

.react-datepicker-ignore-onclickoutside:focus {
  border: 1px solid #f46d25;
}


.react-datepicker-popper {
  z-index: 100;
}

.weeks input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.round {
  margin: 0px 10px;
}

.round label {
  font-size: 18px;
  vertical-align: top;
  margin-left: 4px;
}