.directory-container {
  background: linear-gradient(0deg, #f00 -27.59%, #fff300 124.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.4px;
}
