* {
  box-sizing: border-box;
}

.section {
  width: 100%;

  box-sizing: border-box;
  padding: 3rem 2rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 3rem;
}

.main {
  width: 90%;
}

.topMostSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.topMostSection header {
  font-size: 2rem;
  color: #fff;
}

.topMostSection header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textContainer {
  color: rgba(181, 181, 181, 1);
  line-height: 2rem;
}

.aside {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30%;
  position: sticky;
  top: 6rem;
  align-self: flex-start;
}

.aboutDestination {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 3rem 0rem;
  align-content: center;
}

.textAndImageContainer {
  display: flex;
  gap: 2rem;
}

.textAndImageContainer img {
  width: 40%;
  aspect-ratio: 0.7/0.4;
  height: 100%;
}

.primaryTitle {
  font-size: 1.8rem;
  color: rgba(244, 109, 37, 1);
}

.historyImagesGrid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
}

.historyImagesGrid :is(img) {
  flex: 1 1 3rem;
  width: 8rem;
  aspect-ratio: 1/0.7;
}

.emptyDiv {
  width: 40%;
  aspect-ratio: 1/0.6;
}

.emptyDiv img {
  width: 100%;
  height: 100%;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.emptyDivContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.emptyDivContainer :is(.emptyDiv) {
  flex: 1 1 20%;
}

.offerSection {
  width: 100%;
  box-sizing: border-box;
}

.inlineImageAndText {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0rem;
}

.contentList {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 3rem;
  margin: 3rem 0rem;
}

@media (max-width: 768px) {
  .textAndImageContainer {
    flex-direction: column-reverse;
  }

  .textAndImageContainer img {
    width: 100%;
  }

  .emptyDiv {
    width: 100%;
  }

  .emptyDivContainer :is(.emptyDiv) {
    flex: 1 1 40%;
  }
}
