.gradientButton {
  outline: none;
  border: none;
  color: #fff;
  padding: 0.1rem 0.2rem;
  background: linear-gradient(to right, #fff300, #f46d25);
  border-radius: 0.5rem;
  height: fit-content;
  cursor: pointer;
  box-shadow: 0rem 0rem 1rem #000;
}
