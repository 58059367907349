.offerBannerContainer {
  width: 100%;
  position: relative;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  object-fit: cover;
  margin: 4rem 0rem;
}

.offerBannerContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  top: 0;
  left: 0;
  display: flex;
  z-index: 0;
}

.offerBanner {
  width: 100% !important;
  background-size: cover;
  height: 100%;
  z-index: 0;
}

.offerContent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-sizing: border-box;
  z-index: 1;
  padding: 2rem;
}

.callbackButton {
  background: linear-gradient(
    to right,
    rgba(255, 189, 2, 1),
    rgba(255, 200, 2, 1),
    rgba(255, 87, 3, 1)
  );
  outline: none;
  border: none;
  color: rgba(10, 29, 47, 1);
  font-size: 1.4rem;
  width: 50%;
  padding: 0.5rem 0rem;
  cursor: pointer;
  border-radius: 0.3rem;
  font-weight: bold;
  transition: all 1s;
}

.offerPrimaryTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.offerSecondaryTitle {
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
}
.offerDescription {
  color: #fff;
}

.offerSecondaryTitle span {
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

@media (max-width: 768px) {
  .offerBannerContainer {
    display: none;
  }
}
