@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");

.privacyPolicyContainer {
  padding: 1rem 2rem;
  font: Plus Jakarta Sans;
  margin-top: 4rem;
}

.header {
  background: linear-gradient(#f00, #fff300);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  font-size: 3rem;
}

.paragraph {
  color: rgba(94, 98, 130, 1);
}

.section {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  position: relative;
  overflow: visible;
}

.mainBody {
  flex: 1 1 40rem;
}

.articleHeader {
  background: linear-gradient(#f00, #fff300);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  font-size: clamp(1rem, 10vw, 1.4rem);
}

.articleSummary {
  background: rgba(255, 239, 231, 1);
  border: 1px solid rgba(154, 158, 166, 1);
  border-radius: 0.3rem;
  color: rgba(94, 98, 130, 1);
  font-size: clamp(0.6rem, 7vw, 0.9rem);
  padding: 0.8rem;
  text-wrap: balance;
  /* text-align: justify; */
}

.articleSummary ol li:not(:last-child) {
  margin-bottom: 0.8rem;
}

.aside {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 200px;
  align-self: flex-start;
}

@media (max-width: 768px) {
  .privacyPolicyContainer {
    padding: 1rem 1rem;
    margin-top: 4rem;
  }
}
