.hotelView img {
  display: block;
  margin: auto;
  height: 150px;
  width: 85%;
  border-radius: 3%;
}
.hotelView h4 {
  font-size: 25px;
  font-weight: 600;
  margin: 0px;
}
.hotelView h3 {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  color: #f46d25;
}
.hotelView p {
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}
.hotelView button {
  font-size: 14px;
  font-weight: 400;
  background-color: #f46d25;
  color: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px;
  margin-top: 5px;
}
