@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap");

.container {
  padding: 2rem 2rem;
  margin-top: 3rem;
}

.header {
  font-size: 3rem;
  font-weight: bold;
  /* background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contactContainer {
  flex: 4 1 500px;
}

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.aside {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .container {
    padding: 2rem 1rem;
  }
}
