.section {
  padding: 2rem 2rem;
  /* margin: 3 0px; */
}

.header {
  font-size: clamp(1rem, 6vw, 3rem);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
}

.primaryHeader {
  font-weight: bolder;
}

.secondaryHeader {
  /* background: -webkit-linear-gradient(
    240deg,
    rgba(255, 0, 0, 1),
    rgba(255, 243, 0, 1)
  ); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}

.link {
  font-size: clamp(0.7rem, 3vw, 1.3rem);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .section {
    padding: 2rem 1rem;
  }
}
