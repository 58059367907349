.nextTrip {
  display: flex;
  padding: 5rem 5rem;
  align-items: center;
  position: relative;
  box-sizing: border-box !important;
}

.nextTrip .nextTripBanner {
  width: 100%;
  height: 20rem;
  background-size: cover;
  position: relative;
  box-shadow: 0rem 0rem 1rem #000;
}

.nextTrip .nextTripBanner::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
  z-index: 10;
}

.nextTripContent {
  position: absolute;
  z-index: 20;
  color: rgba(232, 235, 247, 1);
  width: 60%;
  padding-left: 2rem;
}

.nextTripContent header h2 {
  font-size: 2rem;
  width: 60%;
  font-weight: 700;
  margin: 0; /*added margin and h2 tag*/
}

.nextTripContent p {
  width: 70%;
}

.nextTripContent button {
  background: linear-gradient(
    to right,
    rgba(255, 189, 2, 1),
    rgba(255, 200, 2, 1),
    rgba(255, 87, 3, 1)
  );
  outline: none;
  border: none;
  color: rgba(10, 29, 47, 1);
  font-size: 1.4rem;
  width: 50%;
  padding: 0.5rem 0rem;
  cursor: pointer;
  border-radius: 0.3rem;
  font-weight: bold;
  transition: all 1s;
}

.nextTripContent button:hover {
  box-shadow: 0rem 0rem 1rem #000;
}

@keyframes opacityChanger {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .nextTrip {
    display: none;
  }
}
