@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300&display=swap");

.footerContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 2rem;
  background: rgba(20, 20, 20, 1);
  color: rgba(128, 128, 128, 1) !important;
  font: Plus Jakarta Sans;
}

.footer {
  display: flex;
  gap: 3rem;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  align-items: flex-start;
}

.figure {
  font-size: 0.7rem;
  flex: 1 1 200px;
  /* border: 1px solid red; */
  margin: 0;
}

/* .figure :is(figcaption) {
  text-wrap: balance;
} */

.navLinks {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-self: flex-start;
  padding-left: 0rem;
  justify-content: space-between;
  flex: 1 1 100px;
  /* border: 1px solid red; */
  margin-top: 0px;
}

.navLinks :is(li a) {
  color: rgba(128, 128, 128, 1);
  text-decoration: none;
  font-size: 0.7rem;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid red; */
  gap: 2rem;
}

.address::before {
  content: url("../../assets/icons/contact-icons/Vector.svg");
}
.address {
  font-size: 0.8rem;
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.7rem;
  text-wrap: balance;
  padding: 0px;
  margin: 0px;
}
.connect {
  display: flex;
  gap: 1rem;
}

.connect :is(a) {
  text-decoration: none;
  font-size: 0.8rem;
  color: rgba(128, 128, 128, 1) !important;
  display: inline-flex;
  justify-content: center;
}

.contactMail::before {
  content: url("../../assets/icons/contact-icons/mail.svg");
}

.contactMobile::before {
  content: url("../../assets/icons/contact-icons/phone.svg");
}

.horizontalLine {
  background-color: rgba(217, 217, 217, 1);
}
.secondaryContainer {
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.iconContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.icon {
  fill: rgba(128, 128, 128, 1) !important;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
}

.icon:hover {
  fill: #fff !important;
  transform: translateY(-20%);
  transition: all 1s;
}

.innerCircle:hover {
  text-shadow: 0rem 0rem 2rem #fff300;
  color: #fff !important;
}
