@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rozha+One&display=swap");

@font-face {
  font-family: holiday-free;
  src: url("../../assets/fonts/holiday-free-font/HolidayFree.otf")
    format("opentype");
}

.home-header-section {
  aspect-ratio: 1/0.5;
  position: relative;
  background: grey;
  overflow: hidden;
}

.home-header-section img {
  width: 100%;
  height: 100%;
}

.home-header-section .content {
  position: absolute;
  inset: 0;
  z-index: 20;
  padding: 2rem 0rem 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.45);
  gap: 2rem;
}
.current-location-info {
  flex: 1 1 35%;
}

.current-location-info header {
  font-size: clamp(1.5rem, 10vw, 2.3rem);
  font-family: Poppins;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.current-location-info > header > div {
  font-family: holiday-free;
  color: #f46d25;
  font-size: clamp(0.75rem, 2.174vw + -0.239rem, 1.5rem);
}

.current-location-info header .location-name {
  color: #fff;
  font-family: inherit;
  font-size: clamp(2.125rem, 1.39vw + 2.125rem, 3.5rem);
  font-weight: 600;
}

.current-location-info header .location-stay-info {
  font-family: inherit;
  color: #fff;
  font-size: 0.9rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* added for responsiveness for rating below header */
@media screen and (min-width: 320px) and (max-width: 375px) {
  .content .current-location-info .body .location-stay-info {
    gap: 0 !important;
    justify-content: space-between;
  }
}

.current-location-info .body {
  color: #fff;
  font-size: clamp(0.75rem, 0.725vw + 0.42rem, 1rem);
  gap: 0.5rem;
}

.current-location-info .body .contact {
  display: flex;
  gap: 1rem;
  align-items: center !important;
  margin-top: 1rem;
}

.current-location-info .body .contact .linear-gradient-button {
  align-self: center;
}

@keyframes fade-out {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}

.current-content {
  position: absolute;
  inset: 0;
  font-size: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.carousel-container {
  display: inline-block;
  width: 55%;
  overflow: hidden !important;
  padding: 1rem;
  align-self: center;
  animation: fade-in 1s ease-out forwards;
}

.carousel-items-list {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
  position: relative;
}

/* prettier-ignore */
@keyframes shuffle {
  100% {
    left: var(--transform-left)px;
  }
}

.top-values-section {
  box-sizing: border-box;
}

.popular-destinations-container {
  box-sizing: border-box;
  display: flex;
  /* flex-wrap: wrap; */
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 4rem;
  flex-direction: row;
}

.popular-destinations-container > .section-info {
  grid-area: popularDestinationInfo;
  color: #fff;
  flex: 1 1 30%;
}

.popular-destinations-container > .section-info > header {
  font-size: clamp(1.5rem, 10vw, 2.5rem);
  font-weight: 600;
}
.popular-slider {
  display: block;
  flex: 1 1 20rem;
  width: 70%;
  padding: 2rem 0rem;
}

.popular-destinations-container {
  width: 100%;
}

.slider-item {
  background: #000;
  aspect-ratio: 1/1.2;
  width: 30%;
  border: 1px solid red;
  color: #fff;
}

.popular-slider .slick-list .slick-track {
  display: flex;
  gap: 1rem;
  padding: 2rem 0rem;
}

.slick-dots li button::before {
  color: #fff;
  opacity: 1;
}

.slick-active button::before {
  color: #f46d25 !important;
}

.carousel-item {
  min-width: 30%;
  aspect-ratio: 1/1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  backdrop-filter: blur(2rem);
  z-index: 8;
  padding: 0.2rem;
  box-shadow: 0 0 1rem #000;
  /* animation: shuffle 500ms ease-in-out forwards; */
}

.carousel-item img {
  border-radius: inherit;
}

/* ::view-transition-group(carousel-container) {
  height: auto;
  right: 0;
  left: auto;
  transform-origin: left;
} */

.slider-section {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.slider-indicator {
  position: relative;
  height: 0.1rem;
  background: #fff;
  display: flex;
  width: 70%;
  opacity: 0.7;
}

.slider-indicator::after {
  position: absolute;
  inset: 0;
  background-color: #f46d25;
  width: calc(100% / 4);
  height: 0.1rem;
  z-index: 10;
  content: "";
  transform: translateX(calc(var(--item-index) * (100%)));
  transition: transform 500ms ease-in-out;
  transform-origin: left;
  box-shadow: 0rem 0rem 1rem #f46d25;
}

.slider-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/*Monthly deals starts*/

/*Monthly deals ends*/

/*International Packages Starts*/
.international-packages-section {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-areas: "stack";
  aspect-ratio: 1/0.5;
}

.international-packages-section > * {
  grid-area: stack;
}

.international-packages-section .world-map-image {
  width: 100%;
  overflow: hidden;
  position: relative;
  transform: translateY(-20%);
}

/*prettier-ignore*/
.world-map-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.location-pointer {
  position: absolute;
  aspect-ratio: 1;
  z-index: 8;
  border-radius: 50%;
  top: 45%;
  left: 59%;
}

@keyframes ping {
  from {
    transform: scale(0.2);
    opacity: 1;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.location-pointer > div {
  width: 1rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  background: rgba(255, 239, 231, 0.27);
  --pointerBg: rgba(255, 239, 231, 0.27);
  animation: ping 1s linear 0s infinite;
  z-index: 10;
  border: 2px solid #fff;
}

.location-pointer > div::after {
  inset: 4px;
  aspect-ratio: 1;
  position: absolute;
  background-color: #f46d25;
  --pointerBg: #f46d25;
  content: "";
  z-index: 2;
  border-radius: 50%;
  border: none;
  outline: none;
  animation: ping 1s linear 300ms infinite;
  z-index: 10;
  border: 2px solid red;
}

.international-packages-section .international-package-content {
  position: absolute;
  inset: 0;
  content: "";
  width: 100%;
  height: 100%;
}

.international-package-content .content-1 {
  display: flex;
  color: #fff;
  justify-content: center;
  min-height: 30%;
  align-items: center;
  background: linear-gradient(
    rgba(29, 52, 75, 1),
    rgba(29, 52, 75, 0.81),
    rgba(29, 52, 75, 0)
  );
  padding: 2rem;
  gap: 1rem;
}

.international-package-content > .content-1 > .int-pkg-header {
  font-size: clamp(2rem, 10vw, 3rem);
  background: none;
  font-weight: 600;
  z-index: 0;
  width: 100%;
}

.international-package-content > .content-1 > .body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.linear-gradient-button {
  color: #fff;
  background: linear-gradient(98.1deg, #fff300 -82.64%, #f46d25 74.98%);
  padding: 0.2rem 1rem !important;
  align-self: flex-start;
  /* text-shadow: 0rem 0rem 0.2rem #000; */
}

.internation-packages-carousel {
  /* margin: 0rem 2rem; */
  align-content: center;
  height: fit-content;
  position: relative;
}

.internation-packages-carousel > .slick-slider > button {
  display: none !important;
}

.domestic-packages-carousel > .slick-slider > button {
  display: none !important;
}

.internation-packages-carousel > .slick-slider > .slick-dots {
  padding: 0px !important;
}

.internation-packages-carousel > .slick-slider .slick-list > .slick-track {
  display: flex;
  gap: 0.7rem;
}

.internation-packages-carousel
  > .slick-slider
  .slick-list
  > .slick-track
  .slick-slide:not(.slick-current) {
  transform: scaleY(0.92);
  transition: all 250ms;
}

.internation-packages-carousel
  > .slick-slider
  .slick-list
  > .slick-track
  .slick-slide:is(.slick-current, .slick-center) {
  transform: scaleY(1);
  transition: all 250ms;
}

.internation-packages-carousel > .slick-slider .slick-dots > li {
  padding: 0px;
  margin: 0px;
}

/*International Packages Ends*/

/*Domestic Packages Starts*/
.domestic-packages-section {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 3rem 0rem;
  aspect-ratio: 1/0.5;
  background: rgba(15, 28, 45, 1);
}

.domestic-packages-section > .india-map-container {
  height: 100%;
  width: 100%;
  position: relative;
  scale: 1.1;
}

.india-map-container img {
  height: 100%;
}

.domestic-packages-content {
  position: absolute;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  padding-bottom: 6rem;
}

.domestic-packages-content > .dom-header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    rgba(29, 52, 75, 1),
    rgba(29, 52, 75, 0.81),
    rgba(29, 52, 75, 0)
  );
  /* font-size: clamp(2rem, 8vw, 3.2rem); */
  align-items: center;
  /* height: 40%; */
  color: #fff;
  /* font-weight: 600; */
  gap: 1rem;
}

.domestic-packages-content > .dom-header > .sub-header {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  margin: 0rem 18rem;
}

.domestic-packages-carousel {
  justify-content: flex-end;
  width: 60%;
  align-self: flex-end;
  padding-right: 2rem;
  position: relative;
  /* margin-top: 2rem; */
  /* border: 1px solid red; */
}

.domestic-packages-carousel > .slick-slider .slick-list > .slick-track {
  display: flex;
  gap: 0.7rem;
}

.domestic-packages-carousel > .slick-slider .slick-dots > li {
  margin: 0px;
}

.domestic-packages-carousel
  > .slick-slider
  .slick-list
  > .slick-track
  .slick-slide:is(.slick-current) {
  transform: scaleY(1);
  transition: all 0.5s ease-in;
}

.domestic-packages-carousel
  > .slick-slider
  .slick-list
  > .slick-track
  .slick-active:not(.slick-current) {
  transform: scaleY(0.94);
  transition: all 0.5s ease-in;
}

/*Domestic Packages Ends*/

/*Popular destinations styling starts*/

.island-illustration {
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  aspect-ratio: 1/0;
}

.welcome-message-container {
  /* top: clamp(180px, 10vw, 300px); */
  position: absolute;
  padding: 0rem 2rem;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  /* background: red; */
}

.welcome-primary-text {
  font-size: clamp(1.85rem, 7vw, 4.2rem);
  font-weight: 800;
}

.where-text {
  color: white;
}

.where-text span {
  /* background: linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1)); */
  background: linear-gradient(180deg, #ff4300, #ffaa02);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome-message-container p {
  color: white;
  font-size: clamp(1rem, 3vw, 1.6rem);
  font-weight: 500;
  line-height: 50px;
  margin-top: -0.3rem;
  padding: 0rem 0.5rem;
}

.search-option {
  display: flex;
  gap: 10px;
}

.hotel-btn.active-btn,
.package-btn.active-btn {
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 133px;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: linear-gradient(
    to left,
    rgba(255, 87, 3, 1),
    rgba(255, 189, 2, 1),
    rgba(255, 200, 2, 1)
  ); */
  /* ------above is previous linear grdient colour -------- */
  background: linear-gradient(0, #ffaa02, #ff4300);
  color: rgba(10, 29, 47, 1);
  transform: scale(1);
  transition: background transform 1s;
  /* box-shadow: 0rem 0rem 0.5rem #f46d25; */
}

@keyframes activeAnimation {
  from {
    background: rgba(255, 255, 255, 1);
    color: rgba(92, 92, 92, 1);
  }
  to {
    /* background: linear-gradient(
      to left,
      rgba(255, 87, 3, 1),
      rgba(255, 189, 2, 1),
      rgba(255, 200, 2, 1)
    ); */
    background: linear-gradient(0, #ffaa02, #ff4300);
    color: rgba(10, 29, 47, 1);
  }
}

.hotel-btn,
.package-btn {
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 133px;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  color: rgba(92, 92, 92, 1);
  transform: scale(1);
  transition: all;

  /* animation: activeAnimation 0.5s reverse; */
}

.search-fields {
  margin-top: 1rem;
  padding: 10px 0.6rem;
  /* background: rgba(255, 255, 255, 1); */
  /* border: 1px solid rgba(232, 235, 247, 1); */
  border-radius: 4px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.8rem;
  font-family: "Poppins", sans-serif;
  backdrop-filter: blur(7rem);
  box-shadow: 0 0 1rem #000000;
}

.search-fields button {
  border: none;
  /* background: linear-gradient(
    to right,
    rgba(255, 243, 0, 1),
    rgba(255, 67, 0, 1)
  ); */
  background: linear-gradient(0, #ffaa02, #ff4300);
  /* color: white; */
  color: rgba(10, 29, 47, 1);
  padding: 0.3rem 1.8rem;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  flex: 1 1 2rem;
}

.search-fields input::placeholder {
  color: rgba(39, 62, 71, 1);
  font-size: 0.8rem;
  text-align: center;
}

.search-fields input {
  border: none;
  background: rgba(232, 235, 247, 1);
  border-radius: 4px;
  flex: 1 1 3rem;
  padding: 0.2rem 0.3rem;
  height: 2rem;
}

.search-fields input:focus-within {
  border: 1px solid #f46d25;
  padding: 0.2rem 0.3rem;
  height: 2rem;
  outline: none;
  box-sizing: border-box;
}

.offer-banner-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
}
.offer-banner-background-img {
  width: 100%;
  /* height: 100vh; */
}

/* .offer-image-context {
  position: absolute;
  top: 200px;
} */

.offer-body-container {
  top: 0;
  position: absolute;
  display: flex;
  /* background: linear-gradient(90deg, #000 -27.69%, rgba(0, 0, 0, 0) 185.15%); */
  background: linear-gradient(90deg, #0f1d2e -10.69%, rgba(0, 0, 0, 0) 110.15%);
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 2rem;
  box-sizing: border-box;
  /* height: 100vh; */
}
.banner-primary-text {
  color: white;
  font-size: 2.5rem;
  font-weight: 800;
  top: 10px;
}
.banner-primary-text p {
  padding: 0rem;
  margin: 0rem;
  font-size: 2.5rem;
}

.banner-primary-text .home-valid-off {
  /* background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* visibility: hidden;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s, opacity 1s; */
}

.show-offer {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.banner-primary-text .banner-secondary-text {
  font-size: 1.2rem;
  /* width: min(900px, calc(40% + 100px)); */
  width: min(900px, calc(32% + 100px));
  padding: 1rem 0rem;
  font-weight: 100;
}

.offer-btn {
  border: none;
  outline: none;
  /* background: linear-gradient(60deg, rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  font-weight: bold;
  padding: 0.3rem 1.5rem;
  font-size: 20px;
  border-radius: 4px;
  transition: 0.5s;
  width: fit-content;
}

.offer-btn:hover {
  /* transform: scale(1.1);
  background: linear-gradient(60deg, rgba(255, 0, 0, 1), rgba(255, 243, 0, 1)); 
  transition: background 1s all; */
  cursor: pointer;
}

.about-towno {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1rem 2rem;
  flex-wrap: wrap;
}

.about-header {
  background: rgba(255, 236, 225, 1);
  color: #f46d25;
  /* width: 252px; */
  width: 17%;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 2.2rem;
}

.about-text-container {
  flex: 1 1 300px;
}

.about-us-primary-text {
  color: rgba(255, 255, 255, 1);
  font-size: clamp(2rem, 7vw, 4rem);
  text-wrap: balance;
  padding: 1rem 0rem;
}

.about-us-primary-text .boldtext {
  font-weight: bolder;
  background: linear-gradient(180deg, rgba(255, 67, 0), rgba(255, 170, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-us-secondary-text {
  color: rgba(94, 98, 130, 1);
  width: 80%;
  text-wrap: balance;
  font-size: clamp(0.5rem, 4vw, 1rem);
}

.explore-illustration {
  flex: 1 1 200px;
  width: clamp(200px, 10vw, 500px);
}

.contact-us {
  border: none;
  outline: none;
  /* background: linear-gradient(60deg, rgba(255, 243, 0, 1), rgba(255, 67, 0, 1)); */
  background: linear-gradient(0, #ffaa02, #ff4300);
  font-weight: bold;
  border-radius: 4px;
  padding: 0.6rem 1.5rem;
  font-size: 1.2rem;
  transition: 0.5s;
  margin: 1rem 0rem;
  cursor: pointer;
}

.contact-us:hover {
  /* transform: scale(1.1);
  transition: 0.5s; */
  cursor: pointer;
}

.home-blog-section {
  padding: 3rem 2rem;
}

.home-blog-section .blog-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.home-blog-section .blog-title div {
  font-size: clamp(2rem, 10vw, 3.6rem);
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
}

.home-blog-section .blog-title div span {
  background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-blog-section .blog-title .blog-sub-title {
  font-size: clamp(0.5rem, 10vw, 1rem);
  color: rgba(255, 255, 255, 1);
  margin-top: 1rem;
}

.dlteHeader {
  font-size: 2.2rem !important;
  margin: 0rem;
  font-weight: 550;
  font-family: "Rozha One";
}

@media screen and (max-width: 786px) {
  .island-illustration {
    height: 100vh;
  }

  .welcome-message-container {
    padding: 0rem 2rem 0rem 1rem;
  }

  .offer-banner-background-img {
    height: 100vh;
  }

  .home-header-section {
    aspect-ratio: 1/2.5;
    width: 100%;
  }

  .home-header-section .content {
    padding: 5rem 0rem 0rem 0rem;
    display: flex;
    gap: 0rem;
  }

  .current-location-info {
    padding: 0rem 1rem;
  }

  .home-header-section .carousel-container {
    width: fit-content;
    padding: 0rem;
    margin-left: 1rem;
  }

  .carousel-items-list {
    gap: 0.5rem;
  }

  .carousel-item {
    min-width: 60%;
    aspect-ratio: 1/1.4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    backdrop-filter: blur(2rem);
    z-index: 8;
    padding: 0.2rem;
    box-shadow: 0 0 1rem #000;
    /* animation: shuffle 500ms ease-in-out forwards; */
  }

  .current-location-info .body .contact {
    padding: 0rem;
  }
  .popular-destinations-container {
    padding: 0rem;
    gap: 0rem;
    flex-wrap: wrap;
  }

  .popular-destinations-container .section-info {
    padding: 1rem;
  }

  .international-packages-section .world-map-image {
    display: none;
  }

  .international-packages-section {
    width: 100%;
    aspect-ratio: 1/1.87;
  }

  .international-package-content {
    display: flex;
    flex-direction: column;
  }

  .domestic-packages-section {
    width: 100%;
    aspect-ratio: 1/2.3;
  }

  .domestic-packages-section .domestic-packages-content {
    width: 100%;
  }

  .domestic-packages-content .dom-header {
    text-align: center;
  }

  .domestic-packages-content .dom-header .sub-header {
    padding: 1rem;
    margin: 0rem;
  }

  .domestic-packages-section .india-map-container {
    display: none;
  }

  .domestic-packages-carousel {
    width: 100%;
    /* padding: 0rem 1rem; */
    padding-right: 0rem;
  }

  .international-package-content .content-1 {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .dlteHeader {
    font-size: 1.95rem !important;
  }

  /* .internation-packages-carousel
    > .slick-slider
    .slick-list
    > .slick-track
    .slick-slide:not(.slick-current) {
    transform: scale(0.8);
    transition: all 500ms;
  }

  .internation-packages-carousel
    > .slick-slider
    .slick-list
    > .slick-track
    .slick-slide.slick-current {
    transform: scale(0.85);
    transition: all 500ms;
  } */

  .popular-slider {
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .popular-destinations-container {
    margin-top: 80px;
  }

  .home-header-section {
    aspect-ratio: 1/3;
  }
}
