.footer {
  background: #ffffff;
  box-shadow: 0px 1px 5px 2px rgba(244, 109, 37, 0.15);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;

  .subHeader {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px 5px;
    position: relative;
  }

  .more {
    color: #000;
    display: flex;
    align-items: center;
    margin: 0px;
    cursor: pointer;
  }

  .more .item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
  }

  .more .item .caption {
    display: block;
  }
}
