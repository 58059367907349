.container {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  gap: 1.2rem;
  flex-direction: column;
}

.imagePlaceHolder {
  width: 40%;
  aspect-ratio: 1/0.55;
  background: #fff;
}

.imagePlaceHolder img {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  color: rgba(181, 181, 181, 1);
  line-height: 1.7rem;
  text-align: left;
  text-wrap: balance;
}

.title {
  color: rgba(244, 109, 37, 1);
  margin: 0;
}

.content ul {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .imagePlaceHolder {
    width: 100%;
  }

  .content {
    text-align: justify;
  }
}
