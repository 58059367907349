.ul {
  list-style: none;
  /* border: 1px solid rgba(154, 158, 166, 1); */
  width: 100%;
  margin: 0;
  padding: 0rem;
  border-radius: 0;
  box-shadow: 0rem 0rem 0.5rem #000;
  box-sizing: border-box;
}

.listHeader {
  color: rgba(244, 109, 37, 1);
  font-weight: bold;
  padding: 0.3rem;
  background: #fff;
}

.link {
  padding: 0.6rem;
  color: rgba(39, 62, 71, 1);
  transform: translateX(0px);

  font-size: 0.9rem;
  border: 1px solid rgba(154, 158, 166, 1);
  background: #fff;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.link::after {
  background: linear-gradient(45deg, rgba(255, 243, 0, 1), rgba(255, 67, 0, 1));
  position: absolute;
  top: 0;
  content: "";
  width: 100%;
  left: 0;
  height: 100%;
  transform: scale(0, 1);
  transform-origin: right;
  z-index: -1;
}
.link:hover::after {
  transform: scale(1, 1);
}

.link:hover {
  transform: translateX(-10px);
  cursor: pointer;

  color: #fff;
  text-shadow: 0rem 0rem 1rem #000;
}

.navLink {
  text-decoration: none;
  color: inherit !important;
}

.active {
  width: 100%;
  color: #f46d25 !important;
  font-weight: bold;
}

.container {
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  position: sticky;
  z-index: 30;
  top: 3rem;
}

/* @media (max-width: 768px) { */
.ul {
  list-style: none;
  /* border: 1px solid rgba(154, 158, 166, 1); */
  width: 100%;
  display: flex;
  box-shadow: none;
  background: #fff;
  gap: 1rem;
  padding: 1rem 1rem 0rem 1rem;
  align-self: flex-start;
}

.link {
  padding: 0.4rem;
  color: rgba(39, 62, 71, 1);
  transform: none;
  border: none;

  font-size: 0.85rem;
  flex: 1 1 auto;
  position: relative;
  text-decoration: none;
  background: none;
  padding: 0;
  width: fit-content;
  white-space: nowrap;
  padding-bottom: 0.8rem;
  text-align: center;
}

.navLink {
  width: 100%;
  white-space: inherit;
}

.link::after {
  display: none;
}
.link:hover::after {
  transform: scale(1, 1);
}

.link:hover {
  transform: none;
  cursor: pointer;

  color: #f46d25;
  text-shadow: 0rem 0rem 1rem #f46d25;
  font-weight: 600;
}

.active {
  width: 100%;
  /* color: red !important; */
  font-weight: bold;
}

.link:has(> .navLink.active) {
  border-bottom: 4px solid #f46d25 !important;
}

@media (max-width: 768px) {
  .container {
    overflow: scroll;
    scrollbar-width: 10px !important;
  }
  .ul {
    width: fit-content;
  }
}
