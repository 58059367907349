* {
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: black;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: black;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #f46d25;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #f46d25;
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  width: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none;
}

li::marker {
  color: red; /* bullet color */
}
.errors {
  color: red;
  margin: 0px;
}

.css-tlfecz-indicatorContainer {
  color: #f46d25;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  color: #000;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0) !important;
  color: #000 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #f57d3d !important;
  color: #fff !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #f25d0d !important;
}

.twn-primary-btn {
  height: "30px";
  padding: "14px 17.5px";
  font-size: "14px";
  background-color: aqua;
}
