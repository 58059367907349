@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --section-padding: 2rem 5rem;
}

* {
  box-sizing: border-box;
}

.topMostSection {
  padding: var(--section-padding);
  display: flex;
  gap: 4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}
.topMostSection .content {
  color: rgba(181, 181, 181, 1);
  font-size: 1rem;
  line-height: 2rem;
  width: 60%;
}

.header {
  color: #fff;
  font-size: 2rem;
}

.header span {
  background-clip: text;
  background: linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.destinationsInState {
  padding: var(--section-padding);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.destinationInStateCardList {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.cardVariantOne {
  flex: 1 1 auto;
  width: 13%;
  cursor: pointer;
  transform: translateY(0);
  transition: all 0.5s;
}

.cardVariantOne:hover {
  transform: translateY(-10px);
}

.cardVariantOne img {
  width: 100%;
  border-radius: 0.2rem;
  filter: drop-shadow(0rem 0rem 1rem #000);
  aspect-ratio: 1/0.65;
}

.cardVariantOne footer {
  color: rgba(255, 255, 255, 0.81);
}

.seeMore {
  color: #f46d25;
  font-weight: bold;
  align-self: center;
  display: flex;
  transform: translateY(0);
  transition: all 0.5s;
  cursor: pointer;
}
.seeMore :last-child {
  transform: rotate(90deg);
  font-size: 1rem;
}

.seeMore:hover {
  transform: translateY(7px);
}

@media (max-width: 768px) {
  .topMostSection {
    flex-direction: column;
    padding: 1rem;
  }

  .topMostSection .content {
    padding: 1rem;
    width: 100%;
  }

  .destinationsInState {
    padding: 1rem;
  }

  .cardVariantOne {
    min-width: 100%;
  }
}
