.destinationCard {
  /* width: 10rem; */
  flex: 1 1 6rem;
  background: 
    /*this is your grey background*/ linear-gradient(
      to right,
      /* rgba(13, 23, 28, 0), */ rgba(255, 89, 0, 1),
      rgba(255, 255, 255, 0)
    )
    border-box;
  /* color: #313149; */

  border-left: 1.8px solid transparent;
  border-top: 1.5px solid transparent;
  border-right: 1px solid transparent;

  border-radius: 3px;
  display: inline-block;
  position: relative;
  /* margin: 75px 0; */
  cursor: pointer;
}

.destinationBackground {
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  height: 100%;
}
.destinationContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(13, 23, 28, 1), rgba(13, 23, 28, 0));
  text-align: center;
  padding-top: 0.7rem;
}

.destination {
  background: -webkit-linear-gradient(rgba(255, 0, 0, 1), rgba(255, 243, 0, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}
