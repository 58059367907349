.upcoming {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .upcoming td {
    text-align: left;
    padding: 8px;
  }
  .upcoming th {
    text-align: left;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
  }
  
  .radio-group {
    border: solid 1px #f46d25;
    display: inline-block;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .customRadio {
    position: absolute;
    visibility: hidden;
    display: none;
  }
  
  .radio-group label {
    color: #000;
    display: inline-block;
    cursor: pointer;
    padding: 5px 20px;
  }
  
  input[type="radio"]:checked + label {
    color: #fff;
    background: #f46d25;
  }
  
  .radio-group label + input[type="radio"] + label {
    border-left: solid 1px #f46d25;
  }
  
  .accordion {
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 1.1em auto;
  }
  
  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #f46d25;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  }
  
  
  .accordion-title:hover {
    background-color: #f46d25;
  }
  
  .accordion-title {
    padding: 0.5rem;
  }
  
  .accordion-content {
    background-color: #fff;
  }
  .keyIcon {
    vertical-align: middle;
    color: #fff;
    font-size: 20px;
  }
  
  .gridSplit {
    width: calc(100% / 5);
    display: inline-block;
  }
  
  
  
  @media only screen and (max-width: 767px) {
    .gridSplit {
      width: 100%;
    }
  }
  