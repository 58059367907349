.placeCard {
  flex: 1 1 7rem;
  border-radius: 50%;
  max-width: 9rem;
  /* border: 1px solid red; */
  /* width: 5rem; */
  /* height: max-content; */
  padding: 0px;
  display: inline-block;
  /* width: 100px;*/
  height: 9rem;
  background: 
    /*this is your grey background*/ linear-gradient(
      to right,
      /* rgba(13, 23, 28, 0), */ rgba(255, 89, 0, 1),
      rgba(255, 255, 255, 0)
    )
    border-box;
  /* color: #313149; */

  border-left: 1.8px solid transparent;
  border-top: 1.8px solid transparent;
  border-right: 1.5px solid transparent;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  cursor: pointer;
  align-self: flex-start;
}

.cardImage {
  width: 100%;
  border-radius: 50%;
  object-fit: fill;
  height: 100%;
  margin: 0;
}

.content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* z-index: 1; */
  background: linear-gradient(rgba(13, 23, 28, 0.8), rgba(13, 23, 28, 0));
  top: 0;
  border-radius: 50%;
}

.place {
  color: #fff;
  font-weight: bold;
}

/* .placeCard:hover {
  animation: background 4s infinite linear;
} */

/* .placeCard:hover::after {
  box-shadow: 0 2px 0 #ff3d00 inset;
  animation: rotate 2s linear infinite;
} */
