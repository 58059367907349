@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
.contactHeadingBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h1{
        font-family: "Manrope";
        font-weight: 600;
        font-size: 42px;
        margin: 0;
        text-shadow: 1px 2px 2px grey;
        color: whitesmoke;
    }
    p{
        font-size: 21px;
        color: rgba(12, 12, 12, 1);
        font-weight:400;
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {
    .contactHeadingBox{
        h1{
            font-size: 28px;
        }
        p{
            font-size: 16px;
        }
    }
}