.thingsTodo {
  box-sizing: border-box;
  width: 100%;
  padding: 4rem 2rem;
  display: flex;
  gap: 4rem;
}

.todoList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  box-sizing: border-box;
  width: 100%;
}

.imageItem {
  flex: 1 1 7rem;
  /* width: 8.5rem; */
  aspect-ratio: 1/0.7;
  /* background: white; */
  border-radius: 3px;
  border-radius: 0.3rem;
  cursor: pointer;
  transform: translateY(0rem);
  transition: all 0.5s;
}

.imageItem:hover {
  transform: translateY(-0.6rem);
  /* box-shadow: 0rem 0.3rem 0rem #f46d25; */
}

.imageItem img {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  box-shadow: 0rem 0rem 0.7rem #000;
  object-fit: cover;
}
