.cuisineList {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 1.3rem;
  width: 100%;
}

.hr {
  width: 100%;
}
