.best-experiences-section-home {
  padding: 0rem 2rem;
}

.best-experiences-section-home .section-header {
  font-size: clamp(20px, 10vw, 48px);
  color: rgba(232, 235, 247, 1);
  font-weight: 800;
}

.best-experiences-section-home .section-header span {
  /* background: linear-gradient(rgba(255, 243, 0, 1), rgba(255, 0, 0, 1)); */
  background: linear-gradient(180deg,rgba(255,67,0), rgba(255,170,2));
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.best-experiences-container {
  display: flex;
  gap: 1.5rem;
  padding: 2rem 0rem;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .best-experiences-section-home {
    padding: 0rem 1rem;
  }
}
